import React, { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../components/component-items/helpers'
import { Link } from 'react-router-dom'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import GenericModal from '../../../components/component-items/modal'
import { TableCell } from '../../../components/component-items/datatable'

import { IntegrationModalProps, IntegrationResponse } from './types'
import { EventProps, LineItemsProps } from './types'

const createdDateOptions = {
  year: 'numeric' as const,
  month: 'short' as const,
  day: 'numeric' as const,
  hour: 'numeric' as const,
  minute: '2-digit' as const,
  hour12: true, // Use AM/PM
}

export const IntegrationModal: FunctionComponent<IntegrationModalProps> = ({ open, setOpen }) => {
  const { id: order } = useParams<{ id: string }>() || ''
  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(open ? `/api/order/${order}/integration_details/` : '', {})

  const lineItems = loaded ? (resp as any)?.lineItems : []
  const events = loaded ? (resp as any)?.events : []
  const orderDetails = loaded ? (resp as any)?.orderDetails : ''
  const fulfillmentOrderDetails = loaded ? (resp as any)?.fulfillmentOrderDetails : ''

  return (
    <GenericModal
      heading={'Integration Details'}
      show={open}
      size={'xl'}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div style={{ minHeight: 100, padding: '1em' }}>
        <h2 style={{ marginBottom: '1em' }}>Order Integration Line Items</h2>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Originator ID</span>
              </th>
              <th>
                <span className="text">Integration Line Item</span>
              </th>
              <th>
                <span className="text">Kase Line Item</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {lineItems?.map(({ id, theirItem, ourItems }: LineItemsProps) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell input={id} />
                <TableCell>
                  <pre style={{ border: 'solid 1px lightgrey', borderRadius: 4, background: '#f3f3f3' }}>
                    <code>{theirItem}</code>
                  </pre>
                </TableCell>
                <TableCell>
                  {ourItems?.map(({ pvId, quantity, sku }) => (
                    <div key={pvId}>
                      <Link to={`/product/variant/${pvId}/`}>{sku}</Link> - {quantity}
                    </div>
                  ))}
                </TableCell>
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
        {fulfillmentOrderDetails && (
          <>
            <h2 style={{ margin: '1em 0' }}>Fulfillment Order Integration Details</h2>
            <div
              style={{
                maxHeight: 500,
                overflow: 'scroll',
                border: 'solid 1px lightgrey',
                borderRadius: 4,
                background: '#f3f3f3',
              }}
            >
              <pre>
                <code>{fulfillmentOrderDetails}</code>
              </pre>
            </div>
          </>
        )}
        <h2 style={{ margin: '1em 0' }}>Order Integration Details</h2>
        <div
          style={{
            maxHeight: 500,
            overflow: 'scroll',
            border: 'solid 1px lightgrey',
            borderRadius: 4,
            background: '#f3f3f3',
          }}
        >
          <pre>
            <code>{orderDetails}</code>
          </pre>
        </div>
        <h2 style={{ margin: '1em 0' }}>Order Events</h2>
        <GlobalStyles.DataTable style={{ display: 'block', maxHeight: '50vh', overflow: 'scroll' }}>
          <thead>
            <tr>
              <th>
                <span className="text">App Title</span>
              </th>
              <th>
                <span className="text">Attribute to App</span>
              </th>
              <th>
                <span className="text">Attribute to User</span>
              </th>
              <th>
                <span className="text">Critical Alert</span>
              </th>
              <th>
                <span className="text">Message</span>
              </th>
              <th>
                <span className="text">Created</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {events?.map(
              ({ id, appTitle, attributeToApp, attributeToUser, createdAt, criticalAlert, message }: EventProps) => (
                <GlobalStyles.TableRow key={id}>
                  <TableCell>{appTitle}</TableCell>
                  <TableCell>{attributeToApp ? 'True' : 'False'}</TableCell>
                  <TableCell>{attributeToUser ? 'True' : 'False'}</TableCell>
                  <TableCell>{criticalAlert ? 'True' : 'False'}</TableCell>
                  <TableCell>
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                  </TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat('en-US', createdDateOptions).format(new Date(createdAt))}
                  </TableCell>
                </GlobalStyles.TableRow>
              )
            )}
          </tbody>
        </GlobalStyles.DataTable>
        {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      </div>
    </GenericModal>
  )
}

import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useFetch, usePost, StickyElement, notify } from '../../../components/component-items/helpers'
import { OrderOptions } from './styles'
import { globalState } from '../../../store'
import { useTheme } from 'styled-components'
import { useNavigate } from 'react-router-dom'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { FullTabMenuCard } from '../../../components/component-items/tab-menu'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { StatusBarStyled } from '../../../components/component-items/status-bar'
import { FilterListItem } from './styles'
import Form from 'react-bootstrap/Form'
import CardLoading from '../../../components/component-items/loading-popover'

// Cards
import { ShopifySettings } from './settings'
import { StoreFulfillmentOrderList } from './shopify-fulfillment-orders'
import { StoreProductList } from './shopify-products'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import {
  faSync,
  faShippingFast,
  faShippingTimed,
  faSitemap,
  faFileImport,
  faTasks,
  faLink,
  faUnlink,
  faExclamationTriangle,
  faInfoSquare,
} from '@fortawesome/pro-duotone-svg-icons'
import { faShopify } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const MenuOption = ({
  url,
  onClick,
  icon,
  title,
  arrow,
  children,
  node,
}: {
  url?: string
  onClick?: (value: any) => void
  icon: IconProp
  title: string
  arrow?: boolean
  children?: React.ReactElement
  node?: HTMLInputElement | null
}) => {
  const theme = useTheme()
  return (
    // @ts-ignore
    <li ref={node} onClick={onClick}>
      <a href={url || '#'} style={{ minWidth: '250px' }}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: theme.colors.midGray }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const ShopifyIntegrationPage = () => {
  const shopUrl = new URLSearchParams(location.search).get('shop') || ''
  const {
    state: { csrf },
  } = globalState()
  const { response: resp, loaded, error, placeholder }: any = useFetch(`/api/shopify/store-data?shop=${shopUrl}`, {})
  const { current_shop: shop, shopifyshops, company }: any = loaded ? resp : {}
  const { response: storeSettings, loaded: loadedSettings }: any = useFetch(
    `/api/shopify/store-settings?shop=${shop?.shop}`,
    {}
  )

  const tabMapping = [
    {
      label: 'Shopify Fulfillment Orders',
      component: <StoreFulfillmentOrderList shop={shop} storeSettings={storeSettings} />,
      icon: faShippingTimed,
    },
    {
      label: 'Shopify Products',
      component: <StoreProductList {...{ shop, storeSettings }} />,
      icon: faSitemap,
    },
  ]
  const tabOptions = tabMapping.map((tab) => tab.label)
  const [activeTab, setActiveTab] = useState(tabOptions[0])
  const [newShopModal, setNewShopModal] = useState(false)

  const updateActiveTab = (tab: string) => {
    window.location.hash = tab.replaceAll(' ', '-').toLowerCase()
  }

  useEffect(() => {
    if (!tabOptions.includes(activeTab)) {
      setActiveTab(tabOptions[0])
    }
  }, [activeTab])

  useEffect(() => {
    if (loaded) {
      if (resp?.warning) {
        notify({ type: 'warning', message: resp?.warning })
      }
      if (!shopifyshops.length) {
        setNewShopModal(true)
      }
    }
  }, [loaded])

  useEffect(() => {
    const tabLabel = decodeURIComponent(location.hash)?.substring(1)
    const validTab = tabOptions.find((option) => option.replaceAll(' ', '-').toLowerCase() === tabLabel)
    if (validTab) {
      setActiveTab(validTab)
    }
  }, [location.hash])

  const handleImportMissingOrders = async (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    await usePost(`/api/shopify/store-data?shop=${shop.shop}`, { importMissing: true }, csrf, false).then(
      (response) => {
        if (!response.error) {
          notify({ type: 'success', message: 'Order importing has started.' })
        }
      }
    )
  }

  const handleSyncInventory = async (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    await usePost(`/api/shopify/store-data?shop=${shop.shop}`, { syncInventory: true }, csrf, false).then(
      (response) => {
        if (!response.error) {
          notify({
            type: 'success',
            message: 'Inventory sync has been requested. Please allow 10-15 minutes for this to complete. Thank you.',
          })
        }
      }
    )
  }

  return (
    <>
      {loaded && shopifyshops.length ? (
        <>
          <StatusBar {...{ shop, shopifyshops, company }} />
          {loadedSettings && shop?.connected && !storeSettings?.shopifyIntegrationDetails && (
            <StatusBarStyled>
              <div className="header-item">
                <div className="status-icon">
                  <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
                </div>
                <div className="status-text">
                  <strong>Integration Warning</strong>: Kase fulfillment location is disabled. Fulfillment orders and
                  inventory will not sync, you need to re-install{' '}
                  <a href="https://apps.shopify.com/shippingtree" rel="noreferrer" target="_blank">
                    Kase
                  </a>{' '}
                  on Shopify.
                </div>
              </div>
            </StatusBarStyled>
          )}
          <div className="row">
            <div className="col-md-12 col-lg-3 col-xl-2">
              <StickyElement>
                <OrderOptions>
                  <ul>
                    {shop.connected ? (
                      <>
                        <GlobalStyles.Tooltip data-title="Sync all inventory for shopifyshop">
                          <MenuOption onClick={handleSyncInventory} icon={faSync} title={'Sync Inventory'} />
                        </GlobalStyles.Tooltip>
                        <GlobalStyles.Tooltip data-title="Manually import any missing orders">
                          <MenuOption onClick={handleImportMissingOrders} icon={faFileImport} title={'Import Orders'} />
                        </GlobalStyles.Tooltip>
                        <GlobalStyles.Tooltip data-title="Unlink shopifyshop">
                          <MenuOption
                            onClick={() =>
                              usePost(`/api/shopify/store-data?shop=${shop.shop}`, { disconnect: true }, csrf)
                            }
                            icon={faUnlink}
                            title={'Unlink ShopifyShop'}
                          />
                        </GlobalStyles.Tooltip>
                      </>
                    ) : (
                      <GlobalStyles.Tooltip data-title="Connect shopifyshop">
                        <MenuOption url="https://apps.shopify.com/shippingtree" icon={faLink} title={'Reconnect'} />
                      </GlobalStyles.Tooltip>
                    )}
                    <GlobalStyles.Tooltip data-title="Add an additional ShopifyShop">
                      <MenuOption
                        url="https://apps.shopify.com/shippingtree"
                        icon={faShopify}
                        title={'Add ShopifyShop'}
                      />
                    </GlobalStyles.Tooltip>
                  </ul>
                </OrderOptions>
              </StickyElement>
            </div>
            <div className="col-md-12 col-lg-9 col-xl-7">
              <div className="row">
                <div className="col-12">
                  <FullTabMenuCard {...{ tabMapping, activeTab, setActiveTab: updateActiveTab }}>
                    {tabMapping.find((tab) => tab.label === activeTab)?.component}
                  </FullTabMenuCard>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-3">
              <StickyElement>
                <ShopifySettings shop={shop} />
              </StickyElement>
            </div>
          </div>
        </>
      ) : null}
      <AddShopifyShop open={newShopModal} hasShop={loaded ? shopifyshops.length : true} />
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </>
  )
}

export const StatusBar = ({ shop, shopifyshops, company }: { shop: any; shopifyshops: any[]; company: string }) => {
  const navigate = useNavigate()
  return (
    <div className="row" data-pw="shopify-status">
      <div className="col">
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              {shop.connected ? (
                <FontAwesomeIcon icon={faShopify} style={{ color: '#00a58f' }} />
              ) : (
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              )}
            </div>
            <div className="status-text">
              Added on <strong>{shop.created}</strong>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faTasks} />
            </div>
            <div
              className="status-text"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <strong>{company}</strong>
              <strong>
                <StatusBoxStyled className={shop.connected ? 'green' : 'red'} style={{ lineHeight: '.8em' }}>
                  {shop.connected ? 'Connected' : 'Disconnected'}
                </StatusBoxStyled>
              </strong>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text" style={{ paddingRight: '1em' }}>
              Shop:
            </div>
            <div className="status-text">
              <Form.Select
                defaultValue={shop.shop}
                onChange={(e) => navigate(`/integration/shop/?shop=${e.target.value}`)}
                style={{ width: 200 }}
              >
                {shopifyshops.map((s: any, id: number) => (
                  <option value={s.shop} key={id}>
                    {s.shop}
                    {s.disabled ? ' - Disabled' : ''}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </StatusBarStyled>
      </div>
    </div>
  )
}

export const AddShopifyShop = ({ open, hasShop }: { open: boolean; hasShop?: boolean }) => {
  const theme = useTheme()
  const [url, setUrl] = useState('')
  const {
    state: { csrf },
  } = globalState()

  function cleanUrl(url: string) {
    try {
      const cleanStoreUrl = new URL(url.startsWith('http') ? url : `https://${url}`)
      return cleanStoreUrl.hostname
    } catch (e) {
      return url
    }
  }

  const urlValid = () => {
    if (/^[a-zA-Z0-9][a-zA-Z0-9-]*.myshopify.com$/.test(url)) {
      return true
    } else {
      return false
    }
  }

  const connectToShopify = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget

    if (!form.checkValidity()) return

    setUrl(cleanUrl(url))
    if (urlValid()) {
      const resp = await usePost(`/api/shopify/check_shop`, { shop_url: url }, csrf, false, true, true)
      if (resp.error) {
        notify({ type: 'error', message: resp.error })
      } else {
        window.location.href = `/api/shopify/install?shop=${url}`
      }
    } else {
      notify({
        type: 'error',
        message: 'Please insert a valid Shopify store url formatted as: "store-name.myshopify.com"',
      })
    }
  }

  return (
    !hasShop && (
      <GlobalStyles.FullPageCard style={{ minHeight: 300 }}>
        <Form onSubmit={connectToShopify}>
          <div style={{ margin: '2em 1em' }}>
            <FilterListItem>
              <StatusBarStyled style={{ backgroundColor: theme.colors.ghostWhite }}>
                <div className="header-item">
                  <div className="status-icon">
                    <FontAwesomeIcon icon={faInfoSquare} style={{ color: '#800080' }} />
                  </div>
                  <div className="status-text">Connect your ShopifyShop below!</div>
                </div>
              </StatusBarStyled>
              <Form.Label>Shopify Store URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="store-name.myshopify.com"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required
                isInvalid={!!(url && !urlValid())}
              />
            </FilterListItem>
            <FilterListItem>
              <GlobalStyles.Button style={{ minWidth: 125, float: 'right' }} onClick={connectToShopify}>
                Connect
              </GlobalStyles.Button>
            </FilterListItem>
          </div>
        </Form>
      </GlobalStyles.FullPageCard>
    )
  )
}

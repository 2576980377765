// Import External Dependencies
import React, { useContext } from 'react'
import { store } from '../store'
import { Navigate } from 'react-router-dom'
import { Breadcrumb } from 'react-breadcrumbs'
import { Helmet } from 'react-helmet'

import { CrumbRouteProps } from './types'

export const CrumbRoute = ({ children, companyRequired, includeSearch, title, ...rest }: CrumbRouteProps) => {
  const globalState = useContext(store)
  const { state } = globalState
  const { isAuthenticated, userIsWarehouse, actAs } = state

  if (!isAuthenticated) {
    return <Navigate to={`/login?next=${location.pathname}`} />
  }

  if (userIsWarehouse && companyRequired && !actAs) {
    return (
      <Navigate
        to={{
          pathname: '/warehouse/companies',
          search: '?returnto=' + location.pathname,
        }}
      />
    )
  }

  return (
    <Breadcrumb
      data={{
        title: title || rest.title,
        pathname: location.pathname,
        search: includeSearch ? location.search : null,
      }}
    >
      <>
        <Helmet>
          <title>Kase{title ? ` - ${title}` : ''}</title>
          <meta name="viewport" content="initial-scale=1, maximum-scale=0.8" />
        </Helmet>
        {children}
      </>
    </Breadcrumb>
  )
}

export default CrumbRoute

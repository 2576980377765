import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFetch } from '../../components/component-items/helpers'

// Components
import Pager, { Pages } from '../../components/component-items/pager'
import ButtonDropdown from '../../components/component-items/button-dropdown'
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { OrderStatusBox } from '../../components/component-items/status-box'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { TableRow } from './styles'
import { SearchBox } from '../../components/component-items/search'

// Enums
import { ShipNoticeStatus } from '../../enums'

import { AsnRowProps } from './types'

export const AsnRow = ({ id, company, created, updated, sender, delivery, items, status }: AsnRowProps) => {
  var statusId =
    status === ShipNoticeStatus.Completed
      ? 200
      : status === ShipNoticeStatus.Arrived
        ? 12
        : status === ShipNoticeStatus.Processing
          ? 12
          : 100
  var statusName =
    status === ShipNoticeStatus.Completed
      ? 'Completed'
      : status === ShipNoticeStatus.Arrived
        ? 'Arrived'
        : status === ShipNoticeStatus.InTransit
          ? 'In Transit'
          : 'Processing'
  return (
    <TableRow>
      <TableCell input={<Link to={`/asn/${id}/`}>{id}</Link>}>
        {sender ? <div>{sender}</div> : null}
        <div style={{ fontWeight: 500 }}>{company}</div>
        <div>Created: {created}</div>
        <div>Updated: {updated}</div>
        <div>Expected Delivery: {delivery}</div>
      </TableCell>
      <TableCell center>
        {items.length ? (
          <div style={{ maxHeight: 200, overflow: 'scroll', boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px' }}>
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <span className="text" style={{ textAlign: 'left' }}>
                      Product Variant
                    </span>
                  </th>
                  <th>
                    <span className="text center">Sent</span>
                  </th>
                  <th>
                    <span className="text center">Received</span>
                  </th>
                  <th>
                    <span className="text center">Defective</span>
                  </th>
                  <th>
                    <span className="text center">Over/Short</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map(({ id, pId, img, name, sku, sent, received, defective, over_short }) => (
                  <TableRow key={id}>
                    <TableCell
                      center
                      input={<img src={img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />}
                    />
                    <TableCell input={<Link to={`/product/variant/${pId}/`}>{sku}</Link>}>
                      <div>{name}</div>
                    </TableCell>
                    <TableCell input={sent} center />
                    <TableCell input={received} center />
                    <TableCell input={defective} center />
                    <TableCell input={over_short > 0 ? `+${over_short}` : over_short} center />
                  </TableRow>
                ))}
              </tbody>
            </GlobalStyles.DataTable>
          </div>
        ) : null}
      </TableCell>
      <TableCell
        center
        input={<OrderStatusBox style={{ width: 100, margin: 'auto' }} status={statusName} status_id={statusId} />}
      />
    </TableRow>
  )
}

export const AsnList = () => {
  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const navigate = useNavigate()

  // Assign sort based on url params first
  const filterOptions = ['In Transit', 'Arrived', 'Processing', 'Completed', 'All']
  const [filter, setFilter] = useState('All')
  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')

  const [loading, setLoading] = useState(false)

  const url =
    '/api/inventory/asn/?range=' +
    rows +
    '&page=' +
    (page - 1) +
    '&order_by=' +
    Object.keys(sort) +
    '&sort=' +
    Object.values(sort) +
    '&q=' +
    search +
    '&filter=' +
    filter
  const { response, loaded, error, placeholder }: any = useFetch(url, {})

  useEffect(() => {
    setLoading(!loaded)
    if (loaded && response && 'redirect_asn' in response) navigate(`/asn/${response.redirect_asn}/`)
  }, [loaded])

  const asns = loaded ? response.asns : []
  const total = loaded ? response.total : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            ASN List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown title={'Status'} sort={filter} setSort={setFilter} options={filterOptions} height={40} />
          <SearchBox
            style={{ marginLeft: '1em' }}
            {...{ search, setSearch, loading, setLoading, page, setPage }}
            setFocus
            updateURL
          />
          <Link to="/asn/new/">
            <GlobalStyles.Button style={{ height: 40, width: 170 }}>Create ASN</GlobalStyles.Button>
          </Link>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort title={'Details'} sortKey={'id'} {...{ sort, setSort }} />
              <th>
                <span className="text center">Items</span>
              </th>
              <THSort title={'Status'} center {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>{asns?.map((o: any, id: number) => <AsnRow {...o} key={id} />)}</tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

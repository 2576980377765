import * as React from 'react'
import { useContext, useEffect, useLayoutEffect, useState, useRef } from 'react'
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  Route,
  Navigate,
  Routes,
  createSearchParams,
} from 'react-router-dom'
import { store, StateProvider } from './store'
import { KaseTheme } from './theme/KaseTheme'
import KaseToastContainer from './containers/KaseToastContainer'
import { CrumbRoute } from './containers/CrumbRoute'

import 'react-medium-image-zoom/dist/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import { PostPage } from './pages/login/styles'

// Components
import { notify } from './components/component-items/helpers'
import { Chrome, Background, Border } from './components/chrome/chrome'

// Global
import LoginPage from './pages/login'
import { NotFoundPage } from './pages/error'
import { BadRequestPage } from './pages/error/bad-request'
import { WarehouseDashboard, MerchantDashboard } from './pages/dashboard'

// Orders
import { OrderList } from './pages/orders'
import { CreateOrder } from './pages/orders/new'
import { UploadOrders } from './pages/orders/upload'
import { OrderPage } from './pages/orders/order'

//ASNs
import { AsnList } from './pages/asns'
import { CreateAsn } from './pages/asns/new'
import { AsnPage } from './pages/asns/asn'
import { SelectItem } from './pages/asns/asn/item'
import { ConfirmDetails } from './pages/asns/asn/item/confirm-details'
import { ReceiveInventory } from './pages/asns/asn/item/receive-inventory'
import { NonCompliance } from './pages/asns/asn/item/non-compliance'
import { ReturnList } from './pages/returns'

// Products
import { ProductList } from './pages/products'
import { UploadPrices } from './pages/products/upload-prices'
import { CreateProduct } from './pages/products/new'
import { ProductPage } from './pages/products/product'
import { PromoRules } from './pages/products/rules'
import { ProductVariantPage } from './pages/products/product/variant'
import { PackageList } from './pages/packages'
import { CreatePackaging } from './pages/packages/new'
import { PackagingRules } from './pages/packages/rules'

// Postage
import { PostageReconcile } from './pages/postage/reconcile'
import { RateCalculator } from './pages/postage/rate-calculator'
import { PostageManifests } from './pages/postage/manifests'

// Projects
import { Project } from './pages/projects/project'
import { ProjectList } from './pages/projects'

// Locations
import { LocationList } from './pages/locations'
import { LocationEdit } from './pages/locations/edit'
import { LocationView } from './pages/locations/location'

// Company Pages
import { Companies } from './pages/companies'
import { CompanyUsers } from './pages/company/users'
import { CompanySettings } from './pages/company/settings'
import { PackingSlip } from './pages/company/packing-slip'
import { ShippingMethods } from './pages/company/shipping-methods'
import { EmailNotificationList } from './pages/company/email-notifications'
import { Reports } from './pages/company/reports'

// Billing Pages
import { BillingPage } from './pages/billing/header'
import { CommissionsReport } from './pages/billing/commissions'
import { PricingTier } from './pages/billing/pricing-tier'
import { PricingTool } from './pages/billing/pricing-tool'

// Inventory Pages
import { InventoryList } from './pages/inventory'
import { StockAlertsPage } from './pages/company/stock-alerts'
import { RunDownRatePage } from './pages/inventory/run-down-rate'
import { MoveInventoryPage } from './pages/inventory/move-inventory'

// Picking Workflow
import { PickingLocation } from './pages/warehouse/picking/location'
import { MatrixSlotting } from './pages/warehouse/picking/matrix-slotting'
import { BulkSlotting } from './pages/warehouse/picking/bulk-slotting'

// Packing Workflow
import { PackOrder, PrePackOrder } from './pages/warehouse/packing/pack-order'
import { PackSingleOrder } from './pages/warehouse/packing/pack-order/pack-single-order'
import { SerialNumbers } from './pages/warehouse/packing/serial-numbers'
import { ConfirmDimensions, PrePackDimensions } from './pages/warehouse/packing/confirm-dimensions'
import { LabelPurchase } from './pages/warehouse/packing/label-purchase'
import { Freight } from './pages/warehouse/packing/freight'

// Warehouse
import { ShipOrderPage } from './pages/ship-order-page'
import { ExitScanPage } from './pages/exit-scan'
import { WarehouseUsersPage } from './pages/warehouse/users'
import { ReceiveASN } from './pages/asns/receive-asn'

// EDI
import { EDITransactionList } from './pages/edi'

// Automations
import { Actions } from './pages/actions/'

// Batches
import { BatchList } from './pages/warehouse/batches'
import { StartBatch } from './pages/warehouse/batches/start'
import { Batch } from './pages/warehouse/batches/batch'
import { BatchProfile } from './pages/warehouse/batches/batch-profile'

// Integrations
import { ShopifyIntegrationPage } from './pages/integrations/shopify'
import { LoopIntegrationPage } from './pages/integrations/loop'
import { QuickbooksIntegrationPage } from './pages/integrations/quickbooks'
import { ListWebhookPage, WebhookEventsPage } from './pages/company/webhooks'

// Core
import { AsyncTaskList } from './pages/core/tasks'

// User
import { Profile } from './pages/user/profile'

// Keyboard Shortcuts
import { connectShortcuts } from './keyboard-shortcuts'
import { NotificationPage } from './pages/notifications'

const loader = document.querySelector('.loader-container')

const hideLoader = () => loader?.classList.add('loader--hide')

const BatchPageSubRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Batch />} />
      <Route path="start" element={<StartBatch />} />
      <Route path="pack-single" element={<PackSingleOrder />} />
      <Route path="pick/:inventory_item" element={<PickingLocation />} />
      <Route path="pick/:inventory_item/slotting" element={<MatrixSlotting />} />
      <Route path="pick/:inventory_item/bulk-slotting" element={<BulkSlotting />} />
    </Routes>
  )
}

const OrderPageSubRoutes = () => {
  return (
    <Routes>
      <Route path="pack" element={<PackOrder />} />
      <Route path="pre-pack" element={<PrePackOrder />} />
      <Route path="serial-numbers" element={<SerialNumbers />} />
      <Route path="confirm-dimensions" element={<ConfirmDimensions />} />
      <Route path="pre-pack-dimensions" element={<PrePackDimensions />} />
      <Route path="label-purchase" element={<LabelPurchase />} />
      <Route path="freight" element={<Freight />} />
    </Routes>
  )
}

const MainRoutes = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    state: { isAuthenticated, actAs, csrf, userIsWarehouse },
    dispatch,
  } = useContext(store)
  const [isLoading, setLoading] = useState(true)
  const isLoadingRef = useRef(isLoading)
  isLoadingRef.current = isLoading

  let error = new URLSearchParams(location.search).get('error')
  let nextUrl = new URLSearchParams(location.search).get('next')
  let returnTo = new URLSearchParams(location.search).get('returnto')

  var searchParams = new URLSearchParams(location.search)
  searchParams.set('next', location.pathname)

  // Keyboard Shortcuts:
  // Attaches listener on a global level for keyboard shortcuts
  // such as: Start Shipping, Exit Scanning, etc
  useEffect(() => {
    if (userIsWarehouse) {
      const handleKeyDown = (event) => {
        connectShortcuts(event, null, 'global', navigate)
      }

      document.addEventListener('keydown', handleKeyDown)
      return () => document.removeEventListener('keydown', handleKeyDown)
    }
  }, [userIsWarehouse])

  useEffect(() => {
    getCSRF()
    const timer = setTimeout(() => {
      if (isLoadingRef.current) {
        window.location.reload()
      }
    }, 15000)
    return () => clearTimeout(timer)
  }, [])

  useLayoutEffect(() => {
    getSession()
    // Show toast when error is Navigateed with URL
    if (error) {
      notify({ type: 'error', message: error })
      var errorSearchParams = new URLSearchParams(location.search)
      errorSearchParams.delete('error')
      navigate({
        pathname: location.pathname,
        search: createSearchParams(errorSearchParams).toString(),
      })
    }
  }, [])

  const getCSRF = () => {
    fetch('/api/csrf/', {
      credentials: 'include',
    })
      .then((res) => {
        let csrfToken = res.headers.get('X-CSRFToken')
        dispatch({ type: 'csrf', csrf: csrfToken })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSession = async () => {
    fetch('/api/session/', {
      credentials: 'include',
      headers: {
        'X-CSRFToken': csrf,
      },
    })
      .then((res) => res.json())
      .then((payload) => {
        if (payload.isAuthenticated) {
          dispatch({ type: 'session', payload: payload })
        } else {
          throw Error(payload.detail)
        }
        setLoading(false)
        hideLoader()
      })
      .catch((err) => {
        console.log(err)
        getCSRF()
        setLoading(false)
        hideLoader()
      })
  }
  return isLoading ? null : (
    <Routes>
      <Route
        path="/login/"
        element={
          isAuthenticated ? (
            nextUrl ? (
              nextUrl.startsWith('/api') ? (
                window.location.replace(nextUrl)
              ) : (
                <Navigate to={nextUrl} />
              )
            ) : (
              <Navigate to={userIsWarehouse ? '/warehouse/dashboard/' : '/company/dashboard/'} />
            )
          ) : (
            <LoginPage />
          )
        }
      />
      <Route path="/404/" element={<NotFoundPage />} />
      <Route path="/500/" element={<BadRequestPage />} />
      <Route path="/" element={<Navigate to="/login/" />} />
      {isAuthenticated ? (
        <Route
          path="/*"
          element={
            <Chrome>
              <Routes>
                <Route path="core">
                  <Route
                    title="Async Tasks"
                    path="tasks"
                    element={
                      <CrumbRoute title="Async Tasks">
                        <AsyncTaskList />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="warehouse">
                  <Route
                    title="Companies"
                    path="companies"
                    element={
                      <CrumbRoute title="Companies">
                        {returnTo && actAs ? <Navigate to={returnTo} replace /> : <Companies />}
                      </CrumbRoute>
                    }
                  />
                  <Route
                    title="Exit Scan"
                    path="exit-scan"
                    element={
                      <CrumbRoute title="Exit Scan">
                        <ExitScanPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    title="Dashboard"
                    path="dashboard"
                    element={
                      <CrumbRoute title="Dashboard">
                        <WarehouseDashboard />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="users"
                    element={
                      <CrumbRoute title="Warehouse Users">
                        <WarehouseUsersPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    title="Start Shipping"
                    path="start-shipping"
                    element={
                      <CrumbRoute title="Start Shipping">
                        <ShipOrderPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    title="Start Receiving"
                    path="start-receiving"
                    element={
                      <CrumbRoute title="Start Receiving">
                        <ReceiveASN />
                      </CrumbRoute>
                    }
                  />
                  <Route path="order/:id" element={<Navigate to="/order/:id" replace />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="order">
                  <Route
                    index
                    element={
                      <CrumbRoute title="Orders">
                        <OrderList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <CrumbRoute title="New Order">
                        <CreateOrder />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="upload-orders"
                    element={
                      <CrumbRoute title="Upload Orders">
                        <UploadOrders />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <CrumbRoute title="Order Details">
                        <OrderPage />
                      </CrumbRoute>
                    }
                  />
                  <Route path=":id/*" element={<OrderPageSubRoutes />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="batch">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="Batches">
                        <BatchList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <CrumbRoute title="Batch Profile">
                        <BatchProfile />
                      </CrumbRoute>
                    }
                  />
                  <Route path=":id/*" element={<BatchPageSubRoutes />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="asn">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="ASNs">
                        <AsnList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <CrumbRoute title="New ASN">
                        <CreateAsn />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <CrumbRoute title="ASN Details">
                        <AsnPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path=":id/receive"
                    element={
                      <CrumbRoute title="Receive ASN">
                        <SelectItem />
                      </CrumbRoute>
                    }
                  />
                  <Route path=":id/item">
                    <Route
                      path=":shipnotice_item_id"
                      element={
                        <CrumbRoute title="ASN Item">
                          <SelectItem />
                        </CrumbRoute>
                      }
                    />
                    <Route
                      path=":shipnotice_item_id/confirm-details"
                      element={
                        <CrumbRoute title="Confirm Details">
                          <ConfirmDetails />
                        </CrumbRoute>
                      }
                    />
                    <Route
                      path=":shipnotice_item_id/receive-inventory"
                      element={
                        <CrumbRoute title="Receive Inventory">
                          <ReceiveInventory />
                        </CrumbRoute>
                      }
                    />
                    <Route
                      path=":shipnotice_item_id/non-compliance"
                      element={
                        <CrumbRoute title="Non Compliance">
                          <NonCompliance />
                        </CrumbRoute>
                      }
                    />
                  </Route>
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="return">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="Returns">
                        <ReturnList />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="location">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="Locations">
                        <LocationList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="edit"
                    element={
                      <CrumbRoute title="Edit Location">
                        <LocationEdit />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <CrumbRoute title="Location Details">
                        <LocationView />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="product">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="Products">
                        <ProductList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="upload-prices"
                    element={
                      <CrumbRoute title="Upload Prices">
                        <UploadPrices />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="rule"
                    element={
                      <CrumbRoute title="Promo Rules">
                        <PromoRules />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <CrumbRoute title="New Product">
                        <CreateProduct />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="variant/:id"
                    element={
                      <CrumbRoute title="Product Variant">
                        <ProductVariantPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <CrumbRoute title="Product Details">
                        <ProductPage />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="postage">
                  <Route
                    path="reconcile"
                    element={
                      <CrumbRoute title="Postage Reconcile">
                        <PostageReconcile />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="rate-calculator"
                    element={
                      <CrumbRoute title="Rate Calculator">
                        <RateCalculator />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="manifests"
                    element={
                      <CrumbRoute title="Postage Manifests">
                        <PostageManifests />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="project">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="Projects">
                        <ProjectList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <CrumbRoute title="Project Details">
                        <Project />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="edi">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="EDI Transactions">
                        <EDITransactionList />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="action">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="Actions">
                        <Actions />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="package">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="Packages">
                        <PackageList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <CrumbRoute title="New Package">
                        <CreatePackaging />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="rule"
                    element={
                      <CrumbRoute title="Packaging Rules">
                        <PackagingRules />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="inventory">
                  <Route
                    path=""
                    element={
                      <CrumbRoute title="Inventory">
                        <InventoryList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="stock-alerts"
                    element={
                      <CrumbRoute title="Stock Alerts">
                        <StockAlertsPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="run-down-rate"
                    element={
                      <CrumbRoute title="Run Down Rate">
                        <RunDownRatePage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="move"
                    element={
                      <CrumbRoute title="Move Inventory">
                        <MoveInventoryPage />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="billing">
                  <Route
                    path="company"
                    element={
                      <CrumbRoute title="Billing">
                        <BillingPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="commissions"
                    element={
                      <CrumbRoute title="Commissions">
                        <CommissionsReport />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="pricing"
                    element={
                      <CrumbRoute title="Pricing Tool">
                        <PricingTool />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="pricing-tier"
                    element={
                      <CrumbRoute title="Pricing Tier">
                        <PricingTier />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="company">
                  <Route
                    path="dashboard"
                    element={
                      <CrumbRoute title="Dashboard">
                        {userIsWarehouse && !actAs ? <Navigate to="/warehouse/dashboard/" /> : <MerchantDashboard />}
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="users"
                    element={
                      <CrumbRoute title="Company Users">
                        <CompanyUsers />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="reports"
                    element={
                      <CrumbRoute title="Reports">
                        <Reports />
                      </CrumbRoute>
                    }
                  />
                  <Route path="webhooks">
                    <Route
                      path=""
                      element={
                        <CrumbRoute title="Webhooks">
                          <ListWebhookPage />
                        </CrumbRoute>
                      }
                    />
                    <Route
                      path=":id"
                      element={
                        <CrumbRoute title="Webhook Events">
                          <WebhookEventsPage />
                        </CrumbRoute>
                      }
                    />
                  </Route>
                  <Route
                    path="packing-slip"
                    element={
                      <CrumbRoute title="Packing Slip">
                        <PackingSlip />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="shipping-methods"
                    element={
                      <CrumbRoute title="Shipping Methods">
                        <ShippingMethods />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="email-notifications"
                    element={
                      <CrumbRoute title="Email Notifications">
                        <EmailNotificationList />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="settings"
                    element={
                      <CrumbRoute title="Company Settings">
                        <CompanySettings />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <CrumbRoute title="Profile">
                        <Profile />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="integration">
                  <Route path="shopify" element={<Navigate to="shop" />} />
                  <Route
                    path="loop"
                    element={
                      <CrumbRoute title="Loop Integration">
                        <LoopIntegrationPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="quickbooks"
                    element={
                      <CrumbRoute title="QuickBooks Integration">
                        <QuickbooksIntegrationPage />
                      </CrumbRoute>
                    }
                  />
                  <Route
                    path="shop"
                    element={
                      <CrumbRoute title="Shopify Integration">
                        <ShopifyIntegrationPage />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route
                  path="notification"
                  element={
                    <CrumbRoute title="Notifications">
                      <NotificationPage />
                    </CrumbRoute>
                  }
                />

                <Route path="user">
                  <Route
                    path="profile"
                    element={
                      <CrumbRoute title="User Profile">
                        <Profile />
                      </CrumbRoute>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Chrome>
          }
        />
      ) : (
        <Route
          path="/*"
          element={
            <PostPage>
              <Background>
                <Border>
                  <Routes>
                    <Route path="/billing/pricing/" element={<PricingTool />} />
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to={{
                            pathname: '/login',
                            search: `?${searchParams}`,
                          }}
                          replace
                        />
                      }
                    />
                  </Routes>
                </Border>
              </Background>
            </PostPage>
          }
        />
      )}
    </Routes>
  )
}

export const App = () => {
  return (
    <StateProvider>
      <KaseTheme>
        <KaseToastContainer />
        <Router>
          <MainRoutes />
        </Router>
      </KaseTheme>
    </StateProvider>
  )
}

export default App

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Badge, InputGroup } from 'react-bootstrap'
import { DateRangeStyled, FilterListItem } from './styles'
import { Link, useNavigate, useLocation } from 'react-router-dom'
// Components
import Pager, { Pages } from '../../components/component-items/pager'
import React, { useEffect, useState } from 'react'
import { THSort, TableCell } from '../../components/component-items/datatable'

import ButtonDropdown from '../../components/component-items/button-dropdown'
import CardLoading from '../../components/component-items/loading-popover'
import { DateRangePicker } from 'react-date-range'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form'
import GenericModal from '../../components/component-items/modal'
import GlobalStyles from '../../components/component-items/styles'
import { OrderStatusBox } from '../../components/component-items/status-box'
import { SearchBox } from '../../components/component-items/search'
import Select from 'react-select'
import { TableRow } from './styles'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'
import { useFetch } from '../../components/component-items/helpers'

const colourStyles = {
  menu: (styles: any) => ({ ...styles, zIndex: 1000 }),
  option: (styles: any, { data }: any) => {
    return {
      ...styles,
      zIndex: 1000,
      ...(data.group ? { fontWeight: 'normal' } : { paddingLeft: '30px' }),
    }
  },
}

export const FilterProjects = ({
  showFilterModal,
  setFilterModal,
  status: receivedStatus,
  startDate: receivedStartDate,
  endDate: receivedEndDate,
  statusOptions,
  sort,
}: any) => {
  const navigate = useNavigate()
  const [state, setState] = useState([
    {
      startDate: receivedStartDate ? new Date(receivedStartDate) : new Date(),
      endDate: receivedEndDate ? new Date(receivedEndDate) : new Date(),
      key: 'selection',
    },
  ])
  const [addDate, setAddDate] = useState(false)
  const [status, setStatus] = useState(receivedStatus || '')

  const startDate = addDate ? state[0].startDate.toLocaleDateString('en-US').replace(/\//g, '-') : ''
  const endDate = addDate ? state[0].endDate.toLocaleDateString('en-US').replace(/\//g, '-') : ''

  useEffect(() => {
    setStatus(receivedStatus)
  }, [receivedStatus])

  return (
    <GenericModal
      heading={'Filter Projects'}
      show={showFilterModal}
      size="lg"
      onHide={() => setFilterModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => {
            setFilterModal(false)
            navigate({
              search:
                `?start_date=${startDate}&end_date=${endDate}&filter_status=${status}` +
                `${Object.keys(sort).length > 0 ? `&order_by=${Object.keys(sort)}` : ''}` +
                `${Object.values(sort).length > 0 ? `&sort==${Object.values(sort)}` : ''}`,
            })
          }}
        >
          Filter
        </GlobalStyles.Button>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Filter by Project Status</Form.Label>
          <Select
            options={statusOptions}
            value={statusOptions.find((option: any) => option.value === status)}
            onChange={(e: any) => setStatus(e.value)}
            styles={colourStyles}
          />
        </FilterListItem>
        <FilterListItem>
          <Form.Label>Filter By Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={startDate ? startDate + ' to ' + endDate : ''}
              onChange={() => null}
              onFocus={() => setAddDate(true)}
              placeholder="Choose A Date"
              aria-describedby="inputGroupAppend"
              required
            />
            <GlobalStyles.ClearInputButton onClick={() => setAddDate(false)}>Clear</GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
        </FilterListItem>
        {addDate ? (
          <DateRangeStyled>
            {/* @ts-ignore */}
            <DateRangePicker
              onChange={(item) => {
                // @ts-ignore
                setState([item.selection])
              }}
              // @ts-ignore
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              minDate={new Date(2018, 1, 1)}
              maxDate={new Date()}
              editableDateInputs={true}
              ranges={state}
              direction="horizontal"
              className="hide-in-percy"
            />
          </DateRangeStyled>
        ) : null}
      </div>
    </GenericModal>
  )
}

export const ProjectList = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const urlParams = new URLSearchParams(location.search)
  const [page, setPage] = useState(1)
  const tableRowOptions = [100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])

  // Assign sort based on url params first
  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: '10', label: 'Cancelled' },
    { value: '100', label: 'Scheduled' },
    { value: '120', label: 'Released' },
    { value: '140', label: 'Processing' },
    { value: '200', label: 'Closed' },
  ]
  const [sort, setSort] = useState({})
  const [search, setSearch] = useState('')
  const [showFilterModal, setFilterModal] = useState(false)
  const [startDate, setStartDate] = useState(urlParams.get('start_date') || '')
  const [endDate, setEndDate] = useState(urlParams.get('end_date') || '')
  const [status, setStatus] = useState(urlParams.get('filter_status') || '140')

  const [loading, setLoading] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  useEffect(() => {
    setStartDate(urlParams.get('start_date') || '')
    setEndDate(urlParams.get('end_date') || '')
    setStatus(urlParams.get('filter_status') || '140')
  }, [location])

  useEffect(() => {
    const params = new URLSearchParams({ filter_status: status })
    navigate({ search: params.toString() })
  }, [])

  useEffect(() => {
    let counter = 0
    if (status) counter++
    if (startDate || endDate) counter++
    setFilterCount(counter)
  }, [startDate, endDate, status])

  const url =
    '/api/inventory/project/?range=' +
    rows +
    '&page=' +
    (page - 1) +
    '&order_by=' +
    Object.keys(sort) +
    '&sort=' +
    Object.values(sort) +
    '&q=' +
    search +
    '&start_date=' +
    startDate +
    '&end_date=' +
    endDate +
    '&filter_status=' +
    status
  const res: any = useFetch(url, {})

  const projects = res.loaded ? res.response.projects : []

  useEffect(() => {
    setLoading(!res.loaded)
  }, [res.loaded])

  const total = res.loaded ? res.response.total : 0
  const totalPages = res.loaded && total ? Math.ceil(total / rows) : 1

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Project List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {/* @ts-ignore */}
          <ButtonDropdown
            title={'Status'}
            sort={statusOptions?.find((s: any) => s.value == status)?.label || ''}
            setSort={(e: any) =>
              navigate({
                search:
                  `?start_date=${startDate}&end_date=${endDate}&filter_status=${
                    statusOptions?.find((s: any) => s.label === e)?.value || ''
                  }` +
                  `${Object.keys(sort).length > 0 ? `&order_by=${Object.keys(sort)}` : ''}` +
                  `${Object.values(sort).length > 0 ? `&sort==${Object.values(sort)}` : ''}`,
              })
            }
            options={statusOptions.map((s) => s.label)}
          />
          <SearchBox
            {...{ search, setSearch, loading, setLoading, page, setPage }}
            setFocus
            updateURL
            style={{ marginLeft: '.5em' }}
          />
          <GlobalStyles.Button onClick={() => setFilterModal(true)}>
            Filter {filterCount > 0 && <Badge pill>{filterCount}</Badge>}
          </GlobalStyles.Button>
          <FilterProjects {...{ showFilterModal, setFilterModal, status, startDate, endDate, sort, statusOptions }} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort title={'Details'} sortKey={'id'} {...{ sort, setSort }} style={{ width: 150 }} />
              <THSort title={'Bom Variant'} sortKey={'product_variant'} center {...{ sort, setSort }} />
              <THSort title={'Quantity'} center {...{ sort, setSort }} />
              <THSort title={'Due Date'} sortKey={'complete_by'} center {...{ sort, setSort }} />
              <THSort title={'Status'} center {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {projects.map((o: any, id: string | number) => {
              return (
                <TableRow key={id}>
                  <TableCell
                    input={
                      <>
                        <Link to={`/project/${o.id}/`}>{o.id}</Link>
                        <div>{o.company}</div>
                      </>
                    }
                  >
                    {o.created}
                  </TableCell>
                  <TableCell
                    center
                    input={<Link to={`/product/variant/${o.bom_variant.id}/`}>{o.bom_variant.sku}</Link>}
                  >
                    <div>{o.bom_variant.name}</div>
                  </TableCell>
                  <TableCell center input={o.qty} />
                  <TableCell
                    center
                    input={
                      <OrderStatusBox
                        style={{ width: 100, margin: 'auto' }}
                        status={o.complete_by}
                        status_id={o.overdue ? 10 : 100}
                      />
                    }
                  />
                  <TableCell
                    center
                    input={
                      <OrderStatusBox
                        style={{ width: 100, margin: 'auto' }}
                        status={o.status}
                        status_id={o.statusId < 200 ? 100 : 200}
                      />
                    }
                  />
                </TableRow>
              )
            })}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        {/* @ts-ignore */}
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

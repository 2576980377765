import React, { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import CardLoading from '../../../../components/component-items/loading-popover'
import GenericModal from '../../../../components/component-items/modal'

// Plugins
import ReactJson from 'react-json-view'

// TODO - move to folder
type WarehouseShippingOrderProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

export const WarehouseShippingOrderModal: FunctionComponent<WarehouseShippingOrderProps> = ({ open, setOpen }) => {
  const order = useParams<{ id: string }>().id || ''
  const { response: resp, loaded, error, placeholder } = useFetch(open ? `/api/order/${order}/edi_940/` : '', {})

  {
    /* TODO - Add original EDI message here so we can add an option to "reset" */
  }
  const [ediMessage, setEdiMessage] = useState({})

  useEffect(() => {
    if (loaded) {
      setEdiMessage((resp as any)?.edi_message || {})
    }
  }, [resp])

  return (
    <GenericModal
      heading={'940 Warehouse Shipping Order'}
      show={open}
      size={'xl'}
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div style={{ minHeight: 100, padding: '1em' }}>
        <h2 style={{ marginBottom: '1em' }}>EDI Message</h2>
        <div
          style={{
            maxHeight: '70vh',
            overflow: 'scroll',
            border: 'solid 1px lightgrey',
            borderRadius: 4,
            background: '#f3f3f3',
          }}
        >
          <ReactJson
            name={'EDI Messages'}
            src={ediMessage}
            theme="paraiso"
            displayDataTypes={true}
            iconStyle="square"
            displayObjectSize={true}
            indentWidth={4}
            collapsed={false}
            collapseStringsAfterLength={50}
            enableClipboard={true}
          />
        </div>
        {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      </div>
    </GenericModal>
  )
}

// React
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { usePost, notify, usePut } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'

// Enums
import { ShipNoticeStatus } from '../../../../enums'

// Components
import { ProgressList } from '../../../warehouse/packing/styles'
import GlobalStyles from '../../../../components/component-items/styles'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxAlt, faBarcodeRead, faRulerCombined } from '@fortawesome/pro-duotone-svg-icons'

import { MenuOptionProps, ReceivingWorkflowProps, OptionsProps } from './types'

const MenuOption: FunctionComponent<MenuOptionProps> = ({ url, icon, title, active, enabled, onClick, options }) => {
  return (
    <li onClick={onClick} className={active ? 'active' : !enabled ? 'disabled' : ''}>
      {options ? (
        <Row style={{ padding: '.6em' }}>
          {Object.keys(options).map((key, index) => (
            <Col key={index} lg={6}>
              <GlobalStyles.Button
                className="secondary"
                onClick={options[key]}
                style={{ width: '100%', height: 35, margin: '0' }}
                disabled={!active}
              >
                {key}
              </GlobalStyles.Button>
            </Col>
          ))}
        </Row>
      ) : (
        <Link to={url && enabled ? url : '#'}>
          <span className="icon">
            <FontAwesomeIcon icon={icon} />
          </span>
          <span className="text">{title}</span>
        </Link>
      )}
    </li>
  )
}

export const ReceivingWorkflow: FunctionComponent<ReceivingWorkflowProps> = ({
  asn,
  current,
  itemId,
  variantIsConfirmed,
  isProject = false,
}) => {
  const url = `/asn/${asn}/`
  const navigate = useNavigate()
  const {
    state: { csrf },
  } = globalState()

  const onConfirm = async () => {
    navigate(url + 'receive/')
  }
  const handleNextItem = () => {
    if (!isProject) {
      notify({
        title: 'Non-Compliance',
        message:
          'Are there any additional non-compliance fees for this item? You will be able to add and remove fees later in the process as well.',
        type: 'warning',
        onSubmit: onConfirm,
        autoClose: 0,
        requestConfirmation: true,
      })
    } else {
      navigate(url + 'receive/')
    }
  }

  const handleComplete = async () => {
    if (isProject) {
      const resp = await usePut(
        `/api/inventory/asn/${asn}/status/`,
        { status: ShipNoticeStatus.Completed },
        csrf,
        false
      )
      window.location = resp.url
    } else {
      await usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Received }, csrf, false)
      window.location.href = url + 'receive/'
    }
  }

  const [options, setOptions] = useState<OptionsProps>({ 'Next Item': handleNextItem, 'ASN Billing': handleComplete })

  useEffect(() => {
    if (isProject) {
      setOptions({ 'Next Item': handleNextItem, Complete: handleComplete })
    } else {
      setOptions({ 'Next Item': handleNextItem, 'ASN Billing': handleComplete })
    }
  }, [isProject])

  return (
    <ProgressList>
      <ul>
        <MenuOption
          url={url + 'receive/'}
          active={current === 'scan_item'}
          enabled
          icon={faBarcodeRead}
          title={'Select Product'}
        />

        <MenuOption
          url={url + `item/${itemId}/confirm-details/`}
          active={current === 'confirm_details'}
          icon={faRulerCombined}
          enabled={current === 'receive_inventory' || current === 'confirm_details'}
          title={'Confirm Product Details'}
        />

        <MenuOption
          url={url + `item/${itemId}/receive-inventory/`}
          active={current === 'receive_inventory'}
          icon={faBoxAlt}
          enabled={current === 'receive_inventory' || variantIsConfirmed}
          title={'Receive Inventory'}
        />
        <MenuOption active enabled options={options} />
      </ul>
    </ProgressList>
  )
}

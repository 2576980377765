import React, { useState, useRef } from 'react'
import { globalState } from '../../../store'
import { useParams, useNavigate } from 'react-router-dom'
import {
  useFetch,
  usePost,
  usePut,
  useDelete,
  useClick,
  StickyElement,
} from '../../../components/component-items/helpers'
import { BackgroundLayer, OrderOptions, OrderInfo, Dropdown } from './styles'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import { DropdownItem } from '../../../components/component-items/export-tools'

// Cards
import { ProductList } from './product-list'
import { StatusBar } from './status-bar'
import { DetailCard } from './detail-card'
import { Shipping } from './shipping'
import { Billing } from './billing'
import { Label } from './label'

// Enums
import { ShipNoticeStatus } from '../../../enums'

// Modals
import { ComplianceHistory } from './compliance-history'
import { UploadProductModal } from './upload-products-modal'
import { EditASNModal } from './edit-asn-modal'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartRate, faFileUpload, faTrashAlt, faHourglassHalf } from '@fortawesome/pro-duotone-svg-icons'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import {
  faPlaneArrival,
  faPrintSearch,
  faDolly,
  faEdit,
  faClipboardListCheck,
  faCheckDouble,
  faLockOpen,
  faFileInvoiceDollar,
  faBoxCheck,
} from '@fortawesome/pro-duotone-svg-icons'
import { NotificationList } from '../../../components/notifications'

import { MenuOptionProps } from './types'

const MenuOption = ({ url, onClick, icon, title, arrow, children, node }: MenuOptionProps) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const AsnPage = () => {
  let navigate = useNavigate()
  const {
    state: { csrf, userIsWarehouse },
  } = globalState()

  const [openComplianceHistory, setComplianceHistory] = useState(false)
  const [openProductModal, setProductModal] = useState(false)
  const [openEditASNModal, setEditASNModal] = useState(false)

  const asn = useParams<{ id: string }>().id || ''
  const ASNUrl = `/api/inventory/asn/${asn}/details/`
  const { response: resp, error, loaded, placeholder }: any = useFetch(ASNUrl, {})

  const [statusDropdown, setStatusDropdown] = useState(false)
  const node = useRef(null)
  useClick(node, setStatusDropdown)

  const classType = loaded ? resp.classType : ''
  const projectAsn = loaded ? resp.projectAsn : false
  const projectID = loaded ? resp.projectID : ''
  const returnOrder = loaded ? resp.returnOrder : null

  const compliance = loaded ? resp.compliance : {}

  const transferOrder = loaded ? resp.transferOrder : null
  const asnStatus = loaded ? resp.asnStatus : 'In Transit'
  const statusId = loaded ? resp.statusId : ShipNoticeStatus.InTransit
  const lastUpdate = loaded ? resp.lastUpdate : 'Never'
  const company = loaded ? resp.company : ''
  const alternateWarehouses = loaded ? resp.alternateWarehouses : []
  const warehouse = loaded ? resp.warehouse : ''
  const warehouseId = loaded ? resp.warehouseId : ''
  const isOverdue = loaded ? resp.isOverdue : false

  const tracking = loaded && resp.tracking ? resp.tracking : ''
  const totalOrders = loaded ? resp.totalOrders : 0
  const delivery = loaded ? resp.delivery : ''
  const onTime = loaded ? resp.onTime : false
  const sender = loaded && resp.sender ? resp.sender : 'N/A'
  const integrationID = loaded ? resp.integrationID : null
  const inventoryItemLabels = loaded ? resp.inventory_item_labels : 0
  const po = loaded ? resp.po : null
  const bol = loaded ? resp.bol : null
  const pro = loaded ? resp.pro : null
  const serviceLevel = loaded ? resp.service_level : ''

  const products = loaded ? resp.products : []
  const returns = loaded && resp.returns ? resp.returns : []

  const address = loaded ? resp.address : {}

  const transactions = loaded ? resp.transactions : []
  const total = loaded ? resp.total : '0.00'

  const deleteAsn = async () => {
    await useDelete(`/api/inventory/asn/${asn}/`, csrf, false, true, true, () => {
      if (returnOrder) {
        navigate(`/order/${returnOrder}/`)
      } else {
        navigate('/asn/')
      }
    })
  }

  return (
    <>
      <StatusBar {...{ asn, asnStatus, statusId, lastUpdate, company, alternateWarehouses, warehouse, isOverdue }} />
      <div className="row">
        <div className="col-lg-3 col-xl-2">
          <StickyElement>
            <OrderOptions>
              <ul>
                {userIsWarehouse || statusId === ShipNoticeStatus.InTransit ? (
                  <GlobalStyles.Tooltip data-title="Update ASN contents (delivery date, sender, etc)">
                    <MenuOption onClick={() => setEditASNModal(true)} icon={faEdit} title={'Edit ASN'} />
                  </GlobalStyles.Tooltip>
                ) : null}
                {statusId === ShipNoticeStatus.InTransit ? (
                  <GlobalStyles.Tooltip data-title="Upload product list with .csv file">
                    <MenuOption
                      onClick={() => setProductModal(true)}
                      icon={faFileUpload}
                      title={'Upload Product List'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {userIsWarehouse && statusId === ShipNoticeStatus.InTransit ? (
                  <GlobalStyles.Tooltip data-title="Sets ASN status to Arrived">
                    <MenuOption
                      onClick={() =>
                        usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Arrived }, csrf)
                      }
                      icon={faPlaneArrival}
                      title={'Mark as Arrived'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {userIsWarehouse && statusId === ShipNoticeStatus.Arrived ? (
                  <GlobalStyles.Tooltip data-title="Sets ASN status to Processing">
                    <MenuOption
                      onClick={() =>
                        usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Processing }, csrf)
                      }
                      icon={faDolly}
                      title={'Process ASN'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {userIsWarehouse && statusId === ShipNoticeStatus.Completed ? (
                  <GlobalStyles.Tooltip data-title="Sets ASN status back to Arrived">
                    <MenuOption
                      onClick={() => usePut(`/api/inventory/asn/${asn}/reopen/`, {}, csrf)}
                      icon={faLockOpen}
                      title={'Re-open'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {userIsWarehouse &&
                statusId !== ShipNoticeStatus.Completed &&
                statusId !== ShipNoticeStatus.InTransit ? (
                  <GlobalStyles.Tooltip data-title="Sets ASN status to Completed and emails merchant">
                    <MenuOption
                      onClick={() =>
                        usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Completed }, csrf)
                      }
                      icon={faClipboardListCheck}
                      title={'Finish & Notify'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {userIsWarehouse && statusId !== ShipNoticeStatus.Project ? (
                  <GlobalStyles.Tooltip data-title="Billing breakdown for ASN receiving">
                    <MenuOption
                      onClick={() => setComplianceHistory(true)}
                      icon={faFileInvoiceDollar}
                      title={'Compliance'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {statusId <= ShipNoticeStatus.InTransit && projectAsn === false ? (
                  <GlobalStyles.Tooltip data-title="Removes ASN and all associated transactions">
                    <MenuOption onClick={() => deleteAsn()} icon={faTrashAlt} title={'Delete ASN'} />
                  </GlobalStyles.Tooltip>
                ) : null}
                {inventoryItemLabels > 0 && (
                  <GlobalStyles.Tooltip data-title="Print all inventory item barcodes that have not yet been printed for this return">
                    <MenuOption
                      url={`/api/inventory/asn/${asn}/print_inventory_items/?print_type=not_printed`}
                      icon={faPrintSearch}
                      title={'Print Inventory Item Barcode'}
                    />
                  </GlobalStyles.Tooltip>
                )}
                {userIsWarehouse ? (
                  <MenuOption
                    onClick={() => setStatusDropdown(!statusDropdown)}
                    icon={faHeartRate}
                    title={'Change Status'}
                    arrow={true}
                    node={node}
                  >
                    {statusDropdown ? (
                      <Dropdown>
                        <DropdownItem
                          onClick={() =>
                            usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.InTransit }, csrf)
                          }
                          icon={faHourglassHalf}
                          text={'Mark as In Transit'}
                        />
                        <DropdownItem
                          onClick={() =>
                            usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Arrived }, csrf)
                          }
                          icon={faPlaneArrival}
                          text={'Mark as Arrived'}
                        />
                        <DropdownItem
                          onClick={() =>
                            usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Processing }, csrf)
                          }
                          icon={faDolly}
                          text={'Mark as Processing'}
                        />
                        <DropdownItem
                          onClick={() =>
                            usePut(`/api/inventory/asn/${asn}/status/`, { status: ShipNoticeStatus.Received }, csrf)
                          }
                          icon={faBoxCheck}
                          text={'Mark as Received'}
                        />
                        {asnStatus === 'Received' && (
                          <DropdownItem
                            onClick={() => navigate(`/asn/${asn}/receive/`)}
                            icon={faCheckDouble}
                            text={'Complete'}
                          />
                        )}
                      </Dropdown>
                    ) : null}
                  </MenuOption>
                ) : null}
              </ul>
            </OrderOptions>
          </StickyElement>
        </div>
        <div className="col-lg-6 col-xl-7">
          <div className="row">
            <div className="col-xl-12">
              <div className="row" style={{ marginBottom: '20px' }}>
                <div className="col-xl-6">
                  <OrderInfo>
                    <DetailCard
                      {...{
                        asn,
                        projectAsn,
                        projectID,
                        returnOrder,
                        classType,
                        tracking,
                        totalOrders,
                        serviceLevel,
                        delivery,
                        onTime,
                        sender,
                        transferOrder,
                        integrationID,
                        po,
                        bol,
                        pro,
                      }}
                    />
                  </OrderInfo>
                </div>
                <div className="col-xl-6">
                  <OrderInfo>
                    <div className="order-section">
                      <h3>{address.name}</h3>
                      <p>{address.line1}</p>
                      <p>{address.city + ', ' + address.state}</p>
                      <p>{address.zip + ', ' + address.country}</p>
                      <p>{address.phone}</p>
                    </div>
                  </OrderInfo>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <BackgroundLayer>
                <ProductList {...{ products, statusId, userIsWarehouse, warehouseId }} />
              </BackgroundLayer>
              {returns.length ? (
                <BackgroundLayer>
                  <Shipping {...{ returns }} />
                </BackgroundLayer>
              ) : null}
            </div>
            <div className="col-xl-12">
              <div className="row" style={{ marginBottom: '20px' }}>
                <div className="col-xl-6">
                  <Billing {...{ classType, transactions, total }} />
                </div>
                <div className="col-xl-6">
                  <Label labels={[]} print={''} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <StickyElement>
            <NotificationList id={asn} type="shipnotice" />
          </StickyElement>
        </div>
      </div>
      <ComplianceHistory compliance={compliance} open={openComplianceHistory} setOpen={setComplianceHistory} />
      <UploadProductModal open={openProductModal} setOpen={setProductModal} {...{ asn }} />
      <EditASNModal
        open={openEditASNModal}
        setOpen={setEditASNModal}
        {...{ sender, tracking, serviceLevel, delivery, po, bol, pro, integrationID }}
      />
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </>
  )
}

import { Link, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { BackgroundLayer, FilterListItem, DateRangeStyled } from './styles'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import Pager, { Pages } from '../../../components/component-items/pager'
import { TableCell, THSort } from '../../../components/component-items/datatable'
import { SearchBox } from '../../../components/component-items/search'
import GenericModal from '../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'

import { FilterTransactionsProps } from './types'

export const FilterTransactions = ({ open, setOpen, setFilter, categories, search }: FilterTransactionsProps) => {
  let navigate = useNavigate()

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    var startDate = state[0].startDate.toLocaleDateString('en-US')
    var endDate = state[0].endDate.toLocaleDateString('en-US')
    setStartDate(startDate)
    setEndDate(endDate)
  }, [state])

  function handleSubmit() {
    setFilter({
      start_date: startDate.replace(/\//g, '-'),
      end_date: endDate.replace(/\//g, '-'),
      filter_status: status,
    })
    setOpen(false)
  }

  const [response, setResponse] = useState<any>({ success: false, error: false })
  const {
    state: { csrf },
  } = globalState()

  var downloadTransactionsUrl = '/api/billing/transactions/'
  var downloadOrdersUrl = '/api/billing/transactions/'

  const handleExportTransactions = async () => {
    const reload = false
    const stringifyBody = true
    const return_response = true
    const successMessage =
      'Success: Transactions have been requested and you will receive an email once this is complete'
    const errorMessage = <>Something went wrong</>
    const body = {
      start_date: startDate.replace(/\//g, '-'),
      end_date: endDate.replace(/\//g, '-'),
      filter_status: status,
      download: true,
      q: search,
    }
    const resp: any = await usePost(downloadTransactionsUrl, body, csrf, reload, stringifyBody, return_response)
    setResponse(resp.success ? { success: successMessage, error: false } : { success: false, error: errorMessage })
    navigate('/company/reports/')
  }

  const handleExportOrderTransactions = async () => {
    const reload = false
    const stringifyBody = true
    const return_response = true
    const successMessage =
      'Success: Order transactions have been requested and you will receive an email once this is complete'
    const errorMessage = <>Something went wrong</>
    const body = {
      start_date: startDate.replace(/\//g, '-'),
      end_date: endDate.replace(/\//g, '-'),
      filter_status: status,
      download_orders: true,
      q: search,
    }
    const resp: any = await usePost(downloadOrdersUrl, body, csrf, reload, stringifyBody, return_response)
    setResponse(resp.success ? { success: successMessage, error: false } : { success: false, error: errorMessage })
    navigate('/company/reports/')
  }

  return (
    <GenericModal
      heading={'Filter Transactions'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <>
          <GlobalStyles.Button
            style={{ minWidth: 125 }}
            onClick={() => handleExportOrderTransactions()}
            className="secondary"
          >
            Export Orders
          </GlobalStyles.Button>
          <GlobalStyles.Button
            style={{ minWidth: 125 }}
            onClick={() => handleExportTransactions()}
            className="secondary"
          >
            Export Transactions
          </GlobalStyles.Button>
          <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => handleSubmit()}>
            Filter Results
          </GlobalStyles.Button>
        </>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Filter By Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={startDate + ' - ' + endDate}
              onChange={(e) => null}
              onFocus={() => {
                const startDate = state[0].startDate.toLocaleDateString('en-US')
                setStartDate(startDate)
                const endDate = state[0].endDate.toLocaleDateString('en-US')
                setEndDate(endDate)
              }}
              placeholder="Choose A Date"
              aria-describedby="inputGroupAppend"
              required
            />
            <GlobalStyles.ClearInputButton
              onClick={() => {
                setStartDate('')
                setEndDate('')
              }}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
        </FilterListItem>
        {startDate || endDate ? (
          <DateRangeStyled>
            <DateRangePicker
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              months={1}
              minDate={new Date(2018, 1, 1)}
              maxDate={new Date()}
              editableDateInputs={true}
              ranges={state}
              direction="horizontal"
              className="hide-in-percy"
            />
          </DateRangeStyled>
        ) : null}
        <FilterListItem>
          <Form.Label>Filter by Transaction Category</Form.Label>
          <Form.Select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="">All</option>
            {Object.keys(categories).map((k, id) => (
              <option value={categories[k]} key={id}>
                {k}
              </option>
            ))}
          </Form.Select>
          {response.success ? (
            <StatusBoxStyled className={'green'} style={{ marginTop: '2em' }}>
              {response.success}
            </StatusBoxStyled>
          ) : response.error ? (
            <StatusBoxStyled className={'red'} style={{ marginTop: '2em' }}>
              {response.error}
            </StatusBoxStyled>
          ) : null}
        </FilterListItem>
      </div>
    </GenericModal>
  )
}

export const TransactionHistory = ({ categories }: any) => {
  const tableRowOptions = [20, 100, 250, 500]
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(tableRowOptions[0])

  const [sort, setSort] = useState({})
  const [filter, setFilter] = useState({})
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)

  const url = '/api/billing/transactions/'
  const filterOptions = Object.keys(filter)
    .map((key) => key + '=' + filter[key as keyof typeof filter])
    .join('&')
  const queryString = `?range=${rows}&page=${page - 1}&q=${encodeURIComponent(search)}&order_by=${Object.keys(
    sort
  )}&sort=${Object.values(sort)}&${filterOptions}`
  const res = useFetch(url + queryString, {})

  const loaded = res.loaded
  const resp: any = res.response
  useEffect(() => {
    setLoading(!res.loaded)
  }, [res.loaded])

  const transactions = loaded ? resp.transactions : []

  const total = loaded ? resp.total : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  return (
    <BackgroundLayer style={{ display: 'block', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            Transactions<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button className="secondary" style={{ height: 40 }} onClick={() => setOpen(true)}>
            Filter
          </GlobalStyles.Button>
          <FilterTransactions {...{ open, setOpen, setFilter, categories, search }} />
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} style={{ width: 150 }} updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort title={'Date'} sortKey={'created'} {...{ sort, setSort }} />
              <THSort title={'Order'} sortKey={'order__id'} center {...{ sort, setSort }} />
              <th>
                <span className="text center">ASN</span>
              </th>
              <th>
                <span className="text">Transaction</span>
              </th>
              <THSort title={'Amount'} {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {transactions.map((t: any, id: number) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell>{t.created}</TableCell>
                <TableCell
                  center
                  input={
                    <Link to={`/order/${t.order}/`} rel="noreferrer" target="_blank">
                      {t.order}
                    </Link>
                  }
                />
                <TableCell
                  center
                  input={
                    <Link to={`/asn/${t.asn}/`} rel="noreferrer" target="_blank">
                      {t.asn}
                    </Link>
                  }
                />
                <TableCell>{t.description}</TableCell>
                <TableCell input={'$' + t.amount} />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} show={3} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
    </BackgroundLayer>
  )
}

import React, { useState, useEffect, useRef, FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFetch, usePost, notify } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'
import styled from 'styled-components'

// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import GlobalStyles from '../../../../components/component-items/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'
import Checkbox from '../../../../components/component-items/checkbox'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import GenericModal from '../../../../components/component-items/modal'
import Select from 'react-select'
import { HoverNotes } from '../../../../components/component-items/hover-notes'
import { getOrdinal } from '../../../../components/component-items/utils'

// Cards
import { WaveProfile } from './wave-profile'
import { WavePreview } from './wave-preview'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faLayerPlus } from '@fortawesome/pro-duotone-svg-icons'

export type FiltersProps = {
  id: number
  type: string
  result: {
    order_field_type: string
    order_field_operator: string
    order_field_value: string
  }
  logical: (FiltersProps | any)[]
}

const defaultFilters: FiltersProps = {
  id: 1,
  type: '',
  result: {
    order_field_type: '',
    order_field_operator: '',
    order_field_value: '',
  },
  logical: [{}, {}],
}

export type SortProps = {
  id: string
  field_type: string
  field_value: string
  sort: string
}
const defaultSort: SortProps = {
  id: '1',
  field_type: 'order_field',
  field_value: '',
  sort: 'ascending',
}

const AddressFieldType = () => (
  <>
    <option value={''} disabled hidden>
      -Field Type-
    </option>
    <option value={'name'}>Name</option>
    <option value={'email'}>Email</option>
    <option value={'line1'}>Line1</option>
    <option value={'line2'}>Line2</option>
    <option value={'city'}>City</option>
    <option value={'state'}>State</option>
    <option value={'zip'}>Zip</option>
    <option value={'country'}>Country</option>
    <option value={'phone'}>Phone</option>
    <option value={'business'}>Business</option>
  </>
)

const OrderFieldType = () => (
  <>
    <option value={''} disabled hidden>
      -Field Type-
    </option>
    <option value={'id'}>Order ID</option>
    <option value={'created'}>Created</option>
    <option value={'b_to_b'}>B2B</option>
    <option value={'edi'}>EDI</option>
    <option value={'hot'}>Hot</option>
    <option value={'volume'}>Volume (cu ft)</option>
    <option value={'weight'}>Weight (oz)</option>
    <option value={'originator_id'}>originator_id</option>
    <option value={'originator_number'}>originator_number</option>
    <option value={'shop_shipping_method_text'}>Shipping Method Text</option>
    <option value={'saturday_delivery'}>Saturday Delivery</option>
    <option value={'gift'}>Gift</option>
    <option value={'tags'}>Tags (comma separated)</option>
    <option value={'sortable_key'}>Order Items Sortable Key</option>
    <option value={'rush_order'}>Rush Order</option>
    <option value={'ship_before'}>Ship Before Date</option>
    <option value={'ship_after'}>Ship After Date</option>
    <option value={'order_recurring'}>Recurring Orders</option>
    <option value={'customers_first_order'}>First Customer Order</option>
    <option value={'locally_modified'}>Locally Modified</option>
    <option value={'freight'}>Freight</option>
    <option value={'is_replacement'}>Replacement Order</option>
  </>
)

const OrderItemFieldType = () => (
  <>
    <option value={''} disabled hidden>
      -Field Type-
    </option>
    <option value={'product_variant__sku'}>SKU</option>
    <option value={'product_variant__barcode'}>Barcode</option>
    <option value={'quantity'}>Quantity</option>
    <option value={'product_variant__product__promo'}>Promo (true/false)</option>
    <option value={'product_variant__is_bundle'}>Bundle (true/false)</option>
    <option value={'product_variant__weight'}>Variant Weight</option>
    <option value={'product_variant__length'}>Variant Length</option>
    <option value={'product_variant__width'}>Variant Width</option>
    <option value={'product_variant__height'}>Variant Height</option>
  </>
)

const OrderOperators = () => (
  <>
    <option value={''} disabled hidden>
      -Field Operator-
    </option>
    <option value={'exact'}>is</option>
    <option value={'exclude_exact'}>is not</option>
    <option value={'istartswith'}>starts with</option>
    <option value={'iendswith'}>ends with</option>
    <option value={'lt'}>less than</option>
    <option value={'lte'}>less than or equal</option>
    <option value={'gt'}>greater than</option>
    <option value={'gte'}>greater than or equal</option>
    <option value={'icontains'}>contains</option>
    <option value={'iregex'}>matches (regular exp)</option>
    <option value={'isnull'}>is blank</option>
    <option value={'exclude_isnull'}>is not blank</option>
    <option value={'in'}>in (list)</option>
  </>
)

type SortFunctionProps = {
  sort: SortProps[]
  setSort: any
}
export const Sort: FunctionComponent<SortFunctionProps> = ({ sort, setSort }) => {
  function addRow() {
    const id = 'added' + sort.length
    setSort((sort: SortProps[]) => sort.concat({ ...defaultSort, id: id }))
  }

  function handleChange(value: string, type: string, c: any, index: number) {
    setSort((sort: SortProps[]) => [...sort.slice(0, index), { ...c, [type]: value }, ...sort.slice(index + 1)])
  }

  return (
    <GlobalStyles.DataTable>
      <tbody>
        {sort.map((c, index) => (
          <GlobalStyles.TableRow key={index}>
            <TableCell center input={getOrdinal(index + 1)} />
            <TableCell>
              <Form.Select
                required
                value={c.field_type}
                onChange={(e) => handleChange(e.target.value, 'field_type', c, index)}
              >
                <option value={''} disabled hidden>
                  -Field-
                </option>
                <option value={'order_field'}>Order Field</option>
                <option value={'order_item'}>Order Items</option>
              </Form.Select>
            </TableCell>
            {c.field_type === 'order_field' ? (
              <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                <Form.Select
                  required
                  value={c.field_value}
                  onChange={(e) => handleChange(e.target.value, 'field_value', c, index)}
                >
                  <OrderFieldType />
                </Form.Select>
              </TableCell>
            ) : c.field_type === 'address_field' ? (
              <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                <Form.Select
                  required
                  value={c.field_value}
                  onChange={(e) => handleChange(e.target.value, 'field_value', c, index)}
                >
                  <AddressFieldType />
                </Form.Select>
              </TableCell>
            ) : c.field_type === 'order_item' ? (
              <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                <Form.Select
                  required
                  value={c.field_value}
                  onChange={(e) => handleChange(e.target.value, 'field_value', c, index)}
                >
                  <OrderItemFieldType />
                </Form.Select>
              </TableCell>
            ) : null}
            <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
              <Form.Select required value={c.sort} onChange={(e) => handleChange(e.target.value, 'sort', c, index)}>
                <option value={'ascending'}>Ascending</option>
                <option value={'descending'}>Descending</option>
              </Form.Select>
            </TableCell>
            <TableCell
              style={{ width: 75 }}
              input={
                <div className="dropdown__container">
                  {sort.length > 1 ? (
                    <button onClick={() => setSort((sort: SortProps[]) => sort.filter((e: any) => e !== c))}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  ) : null}
                  <button onClick={() => addRow()}>
                    <FontAwesomeIcon icon={faLayerPlus} />
                  </button>
                </div>
              }
              center
            />
          </GlobalStyles.TableRow>
        ))}
      </tbody>
    </GlobalStyles.DataTable>
  )
}

type LogicProps = {
  filters: FiltersProps
  setFilters: (filters: FiltersProps) => void
  children?: React.ReactNode
  r: number
  g: number
  b: number
}
const Logic: FunctionComponent<LogicProps> = ({ filters, setFilters, children, r, g, b }) => {
  const [logical, setLogical] = useState(filters.logical)
  useEffect(() => {
    setFilters({ ...filters, logical: logical })
  }, [logical])

  function addRow() {
    setLogical((logical: FiltersProps[]) => [...logical, defaultFilters])
  }

  const setType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'logical_not') {
      setLogical([defaultFilters])
    } else {
      setLogical([defaultFilters, defaultFilters])
    }
    setFilters({ ...defaultFilters, type: e.target.value })
  }

  const isLogical = ['logical_and', 'logical_or', 'logical_not'].includes(filters.type)
  const isOrderItem = ['order_item_any', 'order_item_all', 'order_item_none'].includes(filters.type)

  return (
    <GlobalStyles.DataTable>
      <tbody>
        <tr style={{ border: 'unset' }}>
          <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
            <Form.Select required value={filters.type} onChange={(e) => setType(e)}>
              <option value={''} disabled hidden>
                -Field-
              </option>
              <option value={'order_field'}>Order Field</option>
              <option value={'address_field'}>Address Field</option>
              <optgroup label="Logical Type">
                <option value={'logical_and'}>AND</option>
                <option value={'logical_or'}>OR</option>
                <option value={'logical_not'}>NOT</option>
              </optgroup>
              <optgroup label="Order Item Options">
                <option value={'order_item_any'}>Match ANY Order Items</option>
                <option value={'order_item_all'}>Match ALL Order Items</option>
                <option value={'order_item_none'}>Match No Order Items</option>
              </optgroup>
            </Form.Select>
            {isLogical ? (
              <GlobalStyles.DataTable>
                <tbody>
                  <tr style={{ border: 'unset' }}>
                    <TableCell style={{ background: `rgb(${r}, ${g}, ${b})`, border: '1px solid #e1eaf1' }}>
                      {logical.map((newFilters: FiltersProps, index: number) => (
                        <React.Fragment key={index}>
                          <Logic
                            filters={newFilters}
                            setFilters={(e: any) =>
                              setLogical((logical: FiltersProps[]) => [
                                ...logical.slice(0, index),
                                { ...e },
                                ...logical.slice(index + 1),
                              ])
                            }
                            r={r * 0.95}
                            g={g * 0.95}
                            b={b * 0.95}
                          >
                            {filters.type !== 'logical_not' ? (
                              <TableCell
                                center
                                style={{ verticalAlign: 'unset', width: 50, background: 'rgb(0,0,0,0)' }}
                                input={
                                  <div className="dropdown__container" style={{ minWidth: 60 }}>
                                    {logical.length > 2 ? (
                                      <button
                                        onClick={() =>
                                          setLogical((logical: FiltersProps[]) =>
                                            logical.filter((c) => c !== newFilters)
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </button>
                                    ) : null}
                                    <button onClick={() => addRow()}>
                                      <FontAwesomeIcon icon={faLayerPlus} />
                                    </button>
                                  </div>
                                }
                              />
                            ) : null}
                          </Logic>
                          {index + 1 !== logical.length ? (
                            <StatusBoxStyled
                              className={'blue'}
                              style={{ margin: '1em auto', width: 100, textAlign: 'center' }}
                            >
                              {filters.type === 'logical_and' ? 'AND' : 'OR'}
                            </StatusBoxStyled>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </TableCell>
                  </tr>
                </tbody>
              </GlobalStyles.DataTable>
            ) : null}
          </TableCell>
          {filters.type === 'order_field' ? (
            <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
              <Form.Select
                required
                value={filters.result.order_field_type}
                onChange={(e) =>
                  setFilters({ ...filters, result: { ...filters.result, order_field_type: e.target.value } })
                }
              >
                <OrderFieldType />
              </Form.Select>
            </TableCell>
          ) : filters.type === 'address_field' ? (
            <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
              <Form.Select
                required
                value={filters.result.order_field_type}
                onChange={(e) =>
                  setFilters({ ...filters, result: { ...filters.result, order_field_type: e.target.value } })
                }
              >
                <AddressFieldType />
              </Form.Select>
            </TableCell>
          ) : isOrderItem ? (
            <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
              <Form.Select
                required
                value={filters.result.order_field_type}
                onChange={(e) =>
                  setFilters({ ...filters, result: { ...filters.result, order_field_type: e.target.value } })
                }
              >
                <OrderItemFieldType />
              </Form.Select>
            </TableCell>
          ) : null}
          {!isLogical ? (
            <>
              <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                <Form.Select
                  required
                  value={filters.result.order_field_operator}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      result: { ...filters.result, order_field_operator: e.target.value },
                    })
                  }
                >
                  <OrderOperators />
                </Form.Select>
              </TableCell>
              {!['is_blank', 'is_not_blank'].includes(filters.result.order_field_operator) ? (
                <TableCell style={{ background: 'rgb(0,0,0,0)' }}>
                  <Form.Control
                    type="text"
                    required
                    placeholder=""
                    value={filters.result.order_field_value}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        result: { ...filters.result, order_field_value: e.target.value },
                      })
                    }
                  />
                </TableCell>
              ) : null}
            </>
          ) : null}
          {children}
        </tr>
      </tbody>
    </GlobalStyles.DataTable>
  )
}

const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
  }
`

export const BatchProfile = () => (
  <>
    <CustomWaveDirective />
    <WaveProfile />
  </>
)

const CustomWaveDirective = () => {
  const [settings, setSettings] = useState({
    companies: [],
    min_orders_batch: 0,
    max_orders_batch: 25,
    min_orders_pre_pack: 20,
    max_orders_pre_pack: 250,
    batch_together: false,
    single_item_batch: false,
    remove_reservations: false,
    ignore_gestation: false,
    sort: false,
    filter: false,
  })
  const node = useRef<HTMLFormElement | null>(null)
  const [validated, setValidated] = useState(false)
  const [nameModal, setNameModal] = useState(false)
  const [preview, setPreview] = useState(false)
  const [sort, setSort] = useState<SortProps[]>([defaultSort])
  const [filters, setFilters] = useState<FiltersProps>(defaultFilters)

  const {
    state: { csrf },
  } = globalState()

  const checkValidity = () => {
    setValidated(true)
    if (node?.current?.checkValidity() === false) {
      return false
    } else if (!settings.companies.length) {
      notify({ type: 'error', message: 'Choose at least one company first.' })
      return false
    }
    return true
  }

  const handleSubmit = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    if (!checkValidity()) {
      return
    }
    usePost(
      '/api/inventory/wave-directive/custom_directive/',
      { ...{ settings, sort, filters } },
      csrf,
      false,
      true,
      true
    ).then((response) => {
      if (response?.error) {
        notify({ type: 'warning', message: response?.error })
      } else {
        notify({ type: 'success', message: 'Batched Successfully' })
      }
    })
  }

  const handlePreview = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    if (!checkValidity()) {
      return
    }

    setPreview(true)
    setValidated(false)
  }

  return (
    <>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Wave Directive</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <Form ref={node} validated={validated} onSubmit={handleSubmit} style={{ padding: '0 1em 2em' }}>
          <WaveDirectiveSettings {...{ settings, setSettings, sort, setSort, filters, setFilters }} showCompanies>
            <GlobalStyles.Button
              type="button"
              className="royal"
              style={{ minWidth: 125, float: 'right' }}
              onClick={() => checkValidity() && setNameModal(true)}
            >
              Save
            </GlobalStyles.Button>
            <GlobalStyles.Button style={{ minWidth: 125, float: 'right' }} onClick={handleSubmit}>
              Run
            </GlobalStyles.Button>
            <GlobalStyles.Button
              className="secondary"
              style={{ minWidth: 125, float: 'right' }}
              onClick={handlePreview}
            >
              Preview
            </GlobalStyles.Button>
          </WaveDirectiveSettings>
        </Form>
      </GlobalStyles.FullPageCard>
      <WavePreview {...{ settings, sort, filters }} open={preview} setOpen={setPreview} />
      <WaveCreate {...{ settings, sort, filters }} open={nameModal} setOpen={setNameModal} />
    </>
  )
}

type WaveDirectiveSettingsProps = {
  settings: any
  setSettings: (settings: any) => void
  sort: any
  setSort: (sort: SortProps[]) => void
  filters: any
  setFilters: (filters: FiltersProps) => void
  showCompanies?: boolean
  children?: React.ReactNode
}

export const WaveDirectiveSettings: FunctionComponent<WaveDirectiveSettingsProps> = ({
  settings,
  setSettings,
  sort,
  setSort,
  filters,
  setFilters,
  showCompanies,
  children,
}) => {
  const url = '/api/company/'
  const { response, loaded, error, placeholder } = useFetch(url, {})
  const company_list = loaded
    ? (response! as any[])
        .sort((a: { grading: number }, b: { grading: number }) => a.grading - b.grading)
        .map((company: { gradingStatus: string; name: any; id: any }) => {
          return {
            label: `(${company.gradingStatus.split('')[0]}) ${company.name}`,
            value: company.id,
          }
        })
    : []

  return (
    <>
      <div style={{ padding: '2em 1em 1em' }}>
        <h3>Batch Settings</h3>
        {showCompanies && (
          <Row>
            <Form.Group as={Col} md="6" className="required">
              <Form.Label>Companies</Form.Label>
              <MultiSelect>
                <Select
                  options={company_list}
                  value={settings.companies}
                  onChange={(e: any) => setSettings({ ...settings, companies: e })}
                  closeMenuOnSelect={false}
                  isMulti
                  className="basic-multi-select"
                  required
                />
              </MultiSelect>
            </Form.Group>
          </Row>
        )}
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Min orders per batch</Form.Label>
            <Form.Control
              type="number"
              name="min_orders_batch"
              placeholder="Min # Batch Orders"
              value={settings.min_orders_batch}
              onChange={(e) => setSettings({ ...settings, min_orders_batch: e.target.value })}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Max orders per batch</Form.Label>
            <Form.Control
              type="number"
              name="max_orders_batch"
              placeholder="Max # Batch Orders"
              value={settings.max_orders_batch}
              onChange={(e) => setSettings({ ...settings, max_orders_batch: e.target.value })}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Min orders per Pre Pack</Form.Label>
            <Form.Control
              type="number"
              name="min_orders_pre_pack"
              placeholder="Min # PrePack Orders"
              value={settings.min_orders_pre_pack}
              onChange={(e) => setSettings({ ...settings, min_orders_pre_pack: e.target.value })}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Max orders per Pre Pack</Form.Label>
            <Form.Control
              type="number"
              name="max_orders_pre_pack"
              placeholder="Max # PrePack Orders"
              value={settings.max_orders_pre_pack}
              onChange={(e) => setSettings({ ...settings, max_orders_pre_pack: e.target.value })}
            />
          </Form.Group>
        </Row>
        {settings.companies.length > 1 && (
          <InputGroup>
            <Checkbox
              selected={settings.batch_together}
              setSelected={() => setSettings({ ...settings, batch_together: !settings.batch_together })}
            />
            <span style={{ margin: '0 1em 1em' }}>
              <HoverNotes
                description="Batching together means orders from multiple companies will be combined."
                title="Batch Orders Together"
              />
            </span>
          </InputGroup>
        )}
        <InputGroup>
          <Checkbox
            selected={settings.single_item_batch}
            setSelected={() => setSettings({ ...settings, single_item_batch: !settings.single_item_batch })}
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>
            <HoverNotes
              description="Filters for only single orders and uses single item batching workflow."
              title="Single Item Batches"
            />
          </p>
        </InputGroup>
        <InputGroup>
          <Checkbox
            selected={settings.remove_reservations}
            setSelected={() => setSettings({ ...settings, remove_reservations: !settings.remove_reservations })}
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>
            <HoverNotes description="Applies to any existing orders not yet batched." title="Remove Reservations" />
          </p>
        </InputGroup>
        <InputGroup>
          <Checkbox
            selected={settings.ignore_gestation}
            setSelected={() => setSettings({ ...settings, ignore_gestation: !settings.ignore_gestation })}
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Ignore Gestation</p>
        </InputGroup>
      </div>
      <h3 style={{ paddingLeft: 12 }}>Order Settings</h3>
      <InputGroup style={{ padding: '1em 0 0 12px' }}>
        <Checkbox selected={settings.sort} setSelected={(e) => setSettings({ ...settings, sort: !settings.sort })} />
        <p style={{ padding: '0 1em', lineHeight: '24px' }}>Sort By Order Field (Default is ascending by order id)</p>
      </InputGroup>
      {settings.sort ? (
        <div style={{ padding: '0 1em 1em' }}>
          <StatusBoxStyled className={'blue'} style={{ textAlign: 'center' }}>
            Please note: boolean fields sort from False {'->'} True when ascending
          </StatusBoxStyled>
          <Sort {...{ sort, setSort }} />
        </div>
      ) : null}
      <InputGroup style={{ padding: '0 0 0 12px' }}>
        <Checkbox
          selected={settings.filter}
          setSelected={() => setSettings({ ...settings, filter: !settings.filter })}
        />
        <p style={{ padding: '0 1em', lineHeight: '24px' }}>Filter Using Logic & Order Fields</p>
      </InputGroup>
      {settings.filter ? (
        <div style={{ padding: '0 1em 1em' }}>
          <StatusBoxStyled className={'blue'} style={{ margin: '0 0.9em', textAlign: 'center' }}>
            Please note: datetime fields must be formatted as 'DD/MM/YYYY'
          </StatusBoxStyled>
          <Logic {...{ filters, setFilters }} r={240} g={248} b={255} />
        </div>
      ) : null}
      <div style={{ padding: '1em 0' }}>{children}</div>
      {!loaded ? <CardLoading error={error} text={placeholder} /> : null}
    </>
  )
}

type WaveCreateProps = {
  open: boolean
  setOpen: (open: boolean) => void
  settings: any
  sort: SortProps[]
  filters: FiltersProps
}
const WaveCreate: FunctionComponent<WaveCreateProps> = ({ open, setOpen, settings, sort, filters }) => {
  const {
    state: { csrf },
  } = globalState()

  const node = useRef<HTMLFormElement | null>(null)
  const [validated, setValidated] = useState(false)
  const navigate = useNavigate()

  const [name, setName] = useState('')

  const handleSubmit = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)
    if (node?.current?.checkValidity() === false) {
      notify({ type: 'error', message: 'Choose a name for this Wave Profile.' })
      return
    }
    usePost('/api/inventory/wave-directive/', { ...{ settings, sort, name, filters } }, csrf, false, true, true).then(
      (response) => {
        if (response?.error) {
          notify({ type: 'warning', message: response?.error })
        }
      }
    )

    navigate('/warehouse/batches/')
  }

  return (
    <GenericModal
      heading={'Wave Profile Name'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={
        <>
          <GlobalStyles.Button onClick={() => setOpen(false)}>Close</GlobalStyles.Button>
          <GlobalStyles.Button onClick={handleSubmit}>Save</GlobalStyles.Button>
        </>
      }
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit} style={{ padding: '2em' }}>
        <Form.Control
          type="text"
          name="name"
          placeholder="Example: 'Under 1lb, excluding B2B'"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
      </Form>
    </GenericModal>
  )
}

import React, { FunctionComponent, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { notify, useFetch, usePost, usePut } from '../../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import CardLoading from '../../../../components/component-items/loading-popover'
import GenericModal from '../../../../components/component-items/modal'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
import { globalState } from '../../../../store'

// Plugins
import ReactJson from 'react-json-view'

// TODO - move to folder
type WarehouseShippingAdviceProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

type EDIMessageResponse = {
  edi_message: any
}

export const WarehouseShippingAdviceModal: FunctionComponent<WarehouseShippingAdviceProps> = ({ open, setOpen }) => {
  const { id: order } = useParams<{ id: string }>() || ''
  const {
    state: { csrf },
  } = globalState()
  const { response: resp, loaded, error, placeholder } = useFetch(open ? `/api/order/${order}/edi_945/` : '', {})

  {
    /* TODO - Add original EDI message here so we can add an option to "reset" */
  }
  const [ediMessage, setEdiMessage] = useState({})

  useEffect(() => {
    if (loaded) {
      setEdiMessage((resp as any)?.edi_message || {})
    }
  }, [resp])

  const handleCreate = () => {
    usePost(`/api/order/${order}/edi_945/`, {}, csrf)
  }

  const handleUpdate = () => {
    usePost(`/api/order/${order}/edi_945/`, { edi_message: ediMessage, update: true }, csrf)
  }

  const handleReCreate = () => {
    const onSubmit = () => {
      usePost(`/api/order/${order}/edi_945/`, { edi_message: ediMessage, recreate: true }, csrf)
    }
    notify({
      title: 'Re-Create EDI Message',
      message:
        'Are you sure you want to re-create the EDI message? This will overwrite the existing EDI message and cannot be undone.',
      type: 'warning',
      onSubmit: onSubmit,
      autoClose: 0,
      requestConfirmation: true,
    })
  }

  const handleSubmit = async () => {
    usePost(`/api/order/${order}/edi_945/`, { edi_message: ediMessage, submit: true }, csrf)
  }

  return (
    <GenericModal
      heading={'945 Warehouse Shipping Advice'}
      show={open}
      size={'xl'}
      onHide={() => setOpen(false)}
      buttons={
        <>
          <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
            Close
          </GlobalStyles.Button>
          {loaded && (resp as any)?.edi_message ? (
            <>
              <GlobalStyles.Button className="primary" style={{ minWidth: 125 }} onClick={handleUpdate}>
                Update
              </GlobalStyles.Button>
              <GlobalStyles.Button className="red" style={{ minWidth: 125 }} onClick={handleReCreate}>
                Re-create
              </GlobalStyles.Button>
              <GlobalStyles.Button className="royal" style={{ minWidth: 125 }} onClick={handleSubmit}>
                Submit
              </GlobalStyles.Button>
            </>
          ) : loaded ? (
            <GlobalStyles.Button className="primary" style={{ minWidth: 125 }} onClick={handleCreate}>
              Create
            </GlobalStyles.Button>
          ) : null}
        </>
      }
    >
      <div style={{ minHeight: 100, padding: '1em' }}>
        <h2 style={{ marginBottom: '1em' }}>EDI Message</h2>
        <div
          style={{
            maxHeight: '70vh',
            overflow: 'scroll',
            border: 'solid 1px lightgrey',
            borderRadius: 4,
            background: '#f3f3f3',
          }}
        >
          {loaded && (resp as any)?.edi_message ? (
            <ReactJson
              name={'EDI Messages'}
              src={ediMessage}
              theme="paraiso"
              displayDataTypes={true}
              iconStyle="square"
              displayObjectSize={true}
              indentWidth={4}
              collapsed={false}
              collapseStringsAfterLength={50}
              enableClipboard={true}
              onEdit={(edit) => setEdiMessage(edit.updated_src)}
              onAdd={(add) => setEdiMessage(add.updated_src)}
              onDelete={(del) => setEdiMessage(del.updated_src)}
            />
          ) : loaded ? (
            <StatusBoxStyled style={{ margin: 'auto' }} className="purple">
              Note: Please create an EDI message before attempting to edit.
            </StatusBoxStyled>
          ) : null}
        </div>
        {/* TODO - Add changes to EDI message in a list format here */}
        {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      </div>
    </GenericModal>
  )
}

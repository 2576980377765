import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch, usePost, StickyElement } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'
import { OrderOptions } from './styles'

// Components
import { PrintInventoryItemsModal, PrintItemType } from '../../../../components/modals'
import { PrintLabelsModal } from '../../../../components/modals/print-labels'
import GlobalStyles from '../../../../components/component-items/styles'

// Cards  & Modals
import { DuplicateModal } from './duplicate-modal'
import { StatusBar } from './status-card'
import { ProductDetails } from './product-details'
import { BundleCard } from './bundle-card'
import { AvailabilityCard } from './availability-card'
import { ProductVariantHistory } from './history-card'
import { ProductVariantSpecifications } from './specifications-card'
import { BOM, BomModal, BuildExplodeBom } from './bom'
import { CasePackCard } from './case-pack-card'
import { ProductAllocation } from './allocation-card'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faInventory,
  faPrintSearch,
  faPrint,
  faProjectDiagram,
  faTrashAlt,
  faTrashUndoAlt,
  faShapes,
  faBomb,
  faList,
  faClone,
  faSync,
} from '@fortawesome/pro-duotone-svg-icons'
import { NotificationList } from '../../../../components/notifications'

import { MenuOptionProps } from './types'

const MenuOption = ({ url, onClick, icon, title, arrow, children, node }: MenuOptionProps) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const ProductVariantPage = () => {
  let navigate = useNavigate()
  const productVariant = useParams<{ id: string }>().id
  const {
    state: { csrf, actAs, userIsWarehouse },
  } = globalState()
  const productVariantUrl = `/api/product/variant/${productVariant}/header/`
  const res: any = useFetch(productVariantUrl, {})

  const loaded = res.loaded
  const resp = res.response

  const [printModal, setPrintModal] = useState(false)
  const [printInventoryItemModal, setPrintInventoryItemModal] = useState(false)
  const [productVariantIdSelected, setProductVariantIdSelected] = useState<any>()
  const [duplicateModal, setDuplicateModal] = useState(false)
  const [openBom, setOpenBom] = useState(false)
  const [openBuildBom, setBuildBom] = useState(false)
  const [explode, setExplode] = useState(false)

  const img = loaded ? resp.img : '/api/static/img/placeholder-product.png'
  const specs = loaded ? resp.specifications : {}
  const bom = loaded ? resp.bom : []
  const isBundle = loaded ? resp.isBundle : false
  const variant = loaded ? resp.variant : {}
  const canDelete = loaded ? resp.canDelete : false
  const isWarehouse = loaded ? resp.isWarehouse : false

  return (
    <>
      <PrintInventoryItemsModal
        itemType={PrintItemType.InventoryItem}
        open={printInventoryItemModal}
        onClose={() => setPrintInventoryItemModal(false)}
        id={productVariantIdSelected || ''}
      />
      <div className="row">
        <div className="col">
          <StatusBar {...variant} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-xl-2">
          <StickyElement>
            <OrderOptions>
              <ul>
                {loaded && userIsWarehouse && (
                  <GlobalStyles.Tooltip data-title="View Inventory for Variant">
                    <MenuOption
                      url={`/inventory/?search=${encodeURIComponent(variant.sku)}`}
                      icon={faInventory}
                      title={'View Inventory'}
                    />
                  </GlobalStyles.Tooltip>
                )}
                {loaded && (
                  <GlobalStyles.Tooltip data-title="Display all orders associated with this variant">
                    <MenuOption
                      onClick={() => {
                        navigate(
                          `/order?${userIsWarehouse && !actAs ? 'search' : 'sku'}=` + encodeURIComponent(variant.sku)
                        )
                      }}
                      icon={faList}
                      title={'View Orders'}
                    />
                  </GlobalStyles.Tooltip>
                )}
                {userIsWarehouse && (
                  <GlobalStyles.Tooltip data-title="Print formatted labels given scancode">
                    <MenuOption onClick={() => setPrintModal(true)} icon={faPrint} title={'Print Labels'} />
                  </GlobalStyles.Tooltip>
                )}
                {resp?.variant?.inventory_item_labels > 0 && userIsWarehouse && (
                  <GlobalStyles.Tooltip data-title="Print all inventory item barcodes that have not yet been printed for this variant">
                    <MenuOption
                      onClick={() => {
                        setPrintInventoryItemModal(true)
                        setProductVariantIdSelected(productVariant)
                      }}
                      icon={faPrintSearch}
                      title={'Print Inventory Item Barcode'}
                    />
                  </GlobalStyles.Tooltip>
                )}
                <PrintLabelsModal
                  open={printModal}
                  setOpen={setPrintModal}
                  id={productVariant || ''}
                  scancode={specs.scancode}
                />
                <GlobalStyles.Tooltip data-title="Displays all variants connected to this variant">
                  <MenuOption onClick={() => setDuplicateModal(true)} icon={faClone} title={'Duplicates'} />
                </GlobalStyles.Tooltip>
                <DuplicateModal open={duplicateModal} setOpen={setDuplicateModal} />
                {variant.originator_id ? (
                  <GlobalStyles.Tooltip data-title="Syncs inventory level with associated integration">
                    <MenuOption
                      onClick={() => usePost(`/api/product/variant/${productVariant}/sync/`, {}, csrf)}
                      icon={faSync}
                      title={'Sync Inventory Quantity'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {!isBundle ? (
                  <GlobalStyles.Tooltip data-title="Quantity change to current BOM">
                    <MenuOption onClick={() => setOpenBom(true)} icon={faProjectDiagram} title={'Update BOM'} />
                  </GlobalStyles.Tooltip>
                ) : null}
                <BomModal open={openBom} setOpen={setOpenBom} />
                {bom.length ? (
                  <GlobalStyles.Tooltip data-title="Begin project building">
                    <MenuOption
                      onClick={() => {
                        setExplode(false)
                        setBuildBom(true)
                      }}
                      icon={faShapes}
                      title={'Build BOM'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {bom.length ? (
                  <GlobalStyles.Tooltip data-title="Destroys current BOM and returns materials">
                    <MenuOption
                      onClick={() => {
                        setExplode(true)
                        setBuildBom(true)
                      }}
                      icon={faBomb}
                      title={'Explode BOM'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                <BuildExplodeBom
                  open={openBuildBom}
                  setOpen={setBuildBom}
                  sku={specs.sku}
                  desc={variant.name}
                  {...{ bom, img, explode }}
                />
                {!variant.deleted ? (
                  canDelete ? (
                    <MenuOption
                      onClick={() =>
                        window.confirm('Are you sure you want to mark this as deleted?')
                          ? usePost(`/api/product/variant/${productVariant}/mark_as_deleted/`, { deleted: true }, csrf)
                          : null
                      }
                      icon={faTrashAlt}
                      title={'Delete Variant'}
                    />
                  ) : null
                ) : (
                  <MenuOption
                    onClick={() =>
                      window.confirm('Are you sure you want to restore this product?')
                        ? usePost(`/api/product/variant/${productVariant}/mark_as_deleted/`, { restore: true }, csrf)
                        : null
                    }
                    icon={faTrashUndoAlt}
                    title={'Restore Variant'}
                  />
                )}
              </ul>
            </OrderOptions>
          </StickyElement>
        </div>
        <div className="col-lg-12 col-xl-7">
          <div className="row">
            <div className="col-xl-12">
              <ProductAllocation />
            </div>
            <div className="col-xl-12">
              <AvailabilityCard />
              {isBundle ? <BundleCard /> : null}
              <ProductVariantSpecifications />
              {bom.length ? <BOM /> : null}
              {!isBundle && isWarehouse ? <CasePackCard /> : null}
              <ProductVariantHistory />
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-3">
          <div className="row">
            <div className="col-xl-12" style={{ marginBottom: 20 }}>
              <ProductDetails />
            </div>
            <div className="col-xl-12" style={{ marginBottom: 20 }}>
              <NotificationList id={productVariant} type="product_variant" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { FunctionComponent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import Form from 'react-bootstrap/Form'
import ReactMarkdown from 'react-markdown'

// Components
import GenericModal from '../../../components/component-items/modal'
import GlobalStyles from '../../../components/component-items/styles'
import { NoteContainer, LargeNoteContainer } from './styles'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import rehypeSanitize from 'rehype-sanitize'

import { EditProjectNotesProps, OrderNotesModalProps } from './types'

export const EditProjectNotes: FunctionComponent<EditProjectNotesProps> = ({ notes, open, setOpen }) => {
  const { id: order }: any = useParams()
  const {
    state: { csrf },
  } = globalState()
  const [note, setNote] = useState(notes)

  return (
    <GenericModal
      heading={'Edit Order Notes'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button onClick={() => usePut(`/api/order/${order}/note/`, { notes: note }, csrf)}>
          Update
        </GlobalStyles.Button>
      }
    >
      <LargeNoteContainer>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Note</Form.Label>
          <Form.Control as="textarea" rows={3} onChange={(e) => setNote(e.target.value)} value={note} />
        </Form.Group>
      </LargeNoteContainer>
    </GenericModal>
  )
}

export const OrderNotesModal: FunctionComponent<OrderNotesModalProps> = ({ notes, hideModal }) => {
  const {
    state: { csrf },
  } = globalState()

  const { id: order } = useParams<{ id: string }>() || ''
  const [open, setOpen] = useState(false)

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Order Notes</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {!hideModal ? (
            <GlobalStyles.Button
              className="secondary"
              onClick={() => usePut(`/api/order/${order}/note/`, { notes: '' }, csrf)}
            >
              Delete
            </GlobalStyles.Button>
          ) : null}
          {!hideModal ? <GlobalStyles.Button onClick={() => setOpen(true)}>Edit</GlobalStyles.Button> : null}
          <EditProjectNotes {...{ notes, open, setOpen }} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <NoteContainer>
        <Form.Group>
          <div className="styled-note">
            <ReactMarkdown rehypePlugins={[rehypeSanitize]}>{notes}</ReactMarkdown>
          </div>
        </Form.Group>
      </NoteContainer>
    </GlobalStyles.FullPageCard>
  )
}

import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import Pager from '../../../components/component-items/pager'
import { useFetch, usePost, notify } from '../../../components/component-items/helpers'
import { Link } from 'react-router-dom'
import { globalState } from '../../../store'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import { Badge, InputGroup } from 'react-bootstrap'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import { SearchBox } from '../../../components/component-items/search'
import { TableCell } from '../../../components/component-items/datatable'
import { TableRow } from '../../orders/styles'
import { FilterListItem, NavButton } from './styles'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble } from '@fortawesome/pro-duotone-svg-icons'
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

const created_date_options = {
  year: 'numeric' as const,
  month: 'short' as const,
  day: 'numeric' as const,
  hour: 'numeric' as const,
  minute: '2-digit' as const,
  hour12: true, // Use AM/PM
}

export const FilterStoreProducts = ({
  showFilterModal,
  setFilterModal,
  storeSettings,
  shop,
}: {
  showFilterModal: boolean
  setFilterModal: (value: any) => void
  storeSettings: any
  shop: string
}) => {
  let navigate = useNavigate()
  const [location, setLocation] = useState({ value: 'all', label: 'All' })
  const [sortKey, setSortKey] = useState('')
  const [reverse, setReverse] = useState(true)

  return (
    <GenericModal
      heading={'Filter Orders'}
      show={showFilterModal}
      size="lg"
      onHide={() => setFilterModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => {
            setFilterModal(false)
            navigate(
              // @ts-ignore
              `?&location=${location.value || ''}&sort_key=${sortKey.value || ''}&reverse=${reverse || false}&shop=${shop}`
            )
          }}
        >
          Filter
        </GlobalStyles.Button>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Filter by location</Form.Label>
          <Select
            options={[
              { value: 'all', label: 'All' },
              { value: 'non_st', label: 'Non-ShippingTree Location' },
              ...(storeSettings?.shopLocations
                ?.filter(
                  (location: any) =>
                    !storeSettings?.fulfillmentLocations
                      .map(({ location }: any) => location.legacyResourceId)
                      .includes(location.legacyResourceId)
                )
                .map((location: any) => ({
                  value: location.legacyResourceId,
                  label: location.name,
                })) || []),
              ...(storeSettings?.fulfillmentLocations?.map(({ location }: any) => ({
                value: location.legacyResourceId,
                label: location.name,
              })) || []),
            ]}
            value={location}
            onChange={(option: any) => {
              return setLocation(option)
            }}
          />
        </FilterListItem>
      </div>
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Sort by</Form.Label>
          <Select
            options={[
              { value: 'FULL_TITLE', label: 'Full title' },
              { value: 'ID', label: 'ID' },
              { value: 'INVENTORY_MANAGEMENT', label: 'Inventory management' },
              { value: 'INVENTORY_POLICY', label: 'Inventory policy' },
              { value: 'INVENTORY_QUANTITY', label: 'Inventory quantity' },
              { value: 'NAME', label: 'Name' },
              { value: 'POPULAR', label: 'Popular' },
              { value: 'POSITION', label: 'Position' },
              { value: 'SKU', label: 'SKU' },
              { value: 'TITLE', label: 'Title' },
            ]}
            value={sortKey}
            onChange={(option: any) => {
              return setSortKey(option)
            }}
          />
          <StatusBoxStyled style={{ margin: '2em auto' }} className="purple">
            Note: These filters are pulled directly from{' '}
            <a href="https://shopify.dev/docs/api/admin-graphql" style={{ color: '#9dabff' }}>
              Shopify's API
            </a>
            . Please refer to their help docs for additional information.
          </StatusBoxStyled>
        </FilterListItem>
      </div>
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <InputGroup>
            <Checkbox selected={reverse} setSelected={() => setReverse(!reverse)} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Reverse order</p>
          </InputGroup>
        </FilterListItem>
      </div>
    </GenericModal>
  )
}

export const StoreProductList = ({ shop, storeSettings }: any) => {
  const location = useLocation()
  const {
    state: { csrf },
  } = globalState()
  const [loading, setLoading] = useState(false)

  // Assign sort based on url params first
  const [showFilterModal, setFilterModal] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  // Filters
  const [stLocation, setStLocation] = useState('')
  const [sortKey, setSortKey] = useState('')
  const [reverse, setReverse] = useState('')

  // Search
  const [search, setSearch] = useState('')

  // Pagination
  const tableRowOptions = [25, 50, 100, 250]
  const [rows, setRows] = useState(tableRowOptions[0])
  // After and before refers to the pointer of the pagination for graphql
  const [after, setAfter] = useState('')
  const [before, setBefore] = useState('')

  const url =
    '/api/shopify/variants/?' +
    'shop=' +
    shop.shop +
    '&range=' +
    rows +
    '&after=' +
    after +
    '&before=' +
    before +
    '&q=' +
    search +
    '&location=' +
    stLocation +
    '&sort_key=' +
    sortKey +
    '&reverse=' +
    reverse
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})
  const { productVariants: variants, pageInfo }: any = resp || {}
  const { hasNextPage, hasPreviousPage, startCursor, endCursor } = pageInfo || {}

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    setStLocation(urlParams.get('location') || 'all')
    setSortKey(urlParams.get('sort_key') || '')
    setReverse(urlParams.get('reverse') || 'false')
  }, [location])

  useEffect(() => {
    let counter = 0
    if (stLocation) counter++
    if (sortKey) counter++
    if (reverse) counter++
    setAfter('')
    setFilterCount(counter)
  }, [stLocation, sortKey, reverse])

  const onClickNextPage = () => {
    setBefore('')
    setAfter(endCursor)
  }

  const onClickPreviousPage = () => {
    setBefore(startCursor)
    setAfter('')
  }

  const handleSyncInventorySingle = async (e: any, variantId: any) => {
    e.preventDefault()
    e.stopPropagation()
    await usePost(url, { pk: variantId }, csrf, false).then((response) => {
      if (!response.error) {
        notify({ type: 'success', message: 'Please allow 2-5 minutes for the sync to complete.' })
      }
    })
  }

  const handleActivateInventorySingle = async (e: any, variantId: any) => {
    e.preventDefault()
    e.stopPropagation()
    usePost(url, { pk: variantId, activate: true }, csrf, false)
  }

  useEffect(() => {
    if (loaded) {
      setLoading(!loaded)
      if (resp.warning) {
        notify({ type: 'warning', message: resp.warning })
      }
    }
  }, [loaded])

  return (
    <>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          {hasPreviousPage && (
            <>
              <NavButton onClick={onClickPreviousPage}>
                <FontAwesomeIcon style={{ marginRight: '4px' }} icon={faArrowCircleLeft} /> Prev Page{' '}
              </NavButton>
              <span>|</span>
            </>
          )}
          {hasNextPage && (
            <NavButton onClick={onClickNextPage}>
              Next Page
              <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faArrowCircleRight} />
            </NavButton>
          )}
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button onClick={() => setFilterModal(true)}>
            Filter {filterCount > 0 && <Badge pill>{filterCount}</Badge>}
          </GlobalStyles.Button>
          <FilterStoreProducts
            showFilterModal={showFilterModal}
            setFilterModal={setFilterModal}
            storeSettings={storeSettings}
            shop={shop.shop}
          />
          <button
            onClick={() => {
              setBefore('')
              setAfter('')
              setSearch('')
            }}
          >
            <Link to={`/integration/shop/`} style={{ maxWidth: 150 }}>
              Reset All
            </Link>
          </button>
          <SearchBox {...{ search, setSearch, loading, setLoading }} setFocus updateURL title={'ID or SKU'} />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, overflow: 'scroll' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text center">Details</span>
              </th>
              <th>
                <span className="text center">SKU</span>
              </th>
              <th>
                <span className="text center">Inventory Management</span>
              </th>
              <th>
                <span className="text center">ST Qty</span>
              </th>
              <th>
                <span className="text">Price</span>
              </th>
              <th>
                <span className="text center">Sync Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loaded
              ? variants.map((variant: any) => (
                  <TableRow key={variant.id}>
                    <TableCell style={{ padding: '1.5em' }}>
                      Shopify ID:{' '}
                      <Link
                        to={'#'}
                        onClick={() =>
                          window.open(
                            `https://${resp.shop}/admin/products/${variant.productId}/variants/${variant.id}`,
                            '_blank'
                          )
                        }
                        style={{ fontWeight: '500' }}
                      >
                        {variant.id}
                      </Link>
                      {variant.ourId ? (
                        <div>
                          ShippingTree ID:{' '}
                          <span style={{ fontWeight: '500' }}>
                            <Link to={`/product/variant/${variant.ourId}/`} style={{ maxWidth: 150 }} target="_blank">
                              {variant.ourId}
                            </Link>
                          </span>
                        </div>
                      ) : null}
                      <div>
                        Created{' '}
                        <span style={{ fontWeight: '500' }}>
                          {new Intl.DateTimeFormat('en-US', created_date_options).format(new Date(variant.created))}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell center input={variant.sku} />
                    <TableCell>
                      <div
                        style={{
                          maxHeight: 200,
                          overflow: 'scroll',
                          boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                        }}
                      >
                        <GlobalStyles.DataTable>
                          <tbody>
                            {variant.inventoryLevels
                              ?.filter((level: any) =>
                                stLocation === level.location.legacyResourceId
                                  ? level.location.name === 'ShippingTree'
                                  : true
                              )
                              .map((level: any) => (
                                <TableRow key={level.location?.id}>
                                  <TableCell>
                                    <div>{level.location?.name}</div>
                                  </TableCell>
                                  <TableCell
                                    input={
                                      level.quantities?.find((quantity: any) => quantity.name === 'available')
                                        ?.quantity || 0
                                    }
                                    center
                                  />
                                </TableRow>
                              ))}
                          </tbody>
                        </GlobalStyles.DataTable>
                      </div>
                    </TableCell>
                    <TableCell center input={variant.ourQty} />
                    <TableCell input={variant.price} />
                    <TableCell
                      center
                      input={
                        variant.hasSTInventoryLevels ? (
                          variant.inSync ? (
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckDouble} />
                          ) : (
                            <Link to={'#'} onClick={(event) => handleSyncInventorySingle(event, variant.ourId)}>
                              Sync Inventory
                            </Link>
                          )
                        ) : (
                          <Link to={'#'} onClick={(event) => handleActivateInventorySingle(event, variant.ourId)}>
                            Add ShippingTree App Location
                          </Link>
                        )
                      }
                    />
                  </TableRow>
                ))
              : null}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <div>
          <Pager options={tableRowOptions} rows={rows} setRows={setRows} />
        </div>
        <div style={{ float: 'right' }}>
          {hasPreviousPage && (
            <>
              <NavButton onClick={onClickPreviousPage}>
                <FontAwesomeIcon style={{ marginRight: '4px' }} icon={faArrowCircleLeft} /> Prev Page{' '}
              </NavButton>
              <span>|</span>
            </>
          )}
          {hasNextPage && (
            <NavButton onClick={onClickNextPage}>
              Next Page
              <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faArrowCircleRight} />
            </NavButton>
          )}
        </div>
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </>
  )
}
